import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Card,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import {
  CLIENT360AGGREGATES,
  GETALLINSURANCECOMPANIES,
  GETALLINTERMEDIARIESAGENT,
  GETALLINTERMEDIARIESBROKER,
} from "../../../GraphQl/queries";
import {
  ClientAggregateType,
  InsuranceCompanyType,
  IntermediariesAgentType,
  IntermediariesBrokerType,
} from "../../../types/query";
import { useQuery } from "@apollo/client";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { AgentDetail, DetailDrawer } from "../../../components";
import BrokerDetail from "../../../components/Details/broker-detail";
import { UserType } from "../../../types/user";
import { DEFAULTPRIMARYCOLOR } from "../../../util";

const ClientView = () => {
  const filterOptions = ["Agents", "Staff"];
  const [selectedfilter, setFilter] = useState("Agents");
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<
    | InsuranceCompanyType
    | IntermediariesBrokerType
    | IntermediariesAgentType
    | undefined
  >();

  const PAGE_SIZE = 20;

  const {
    data,
    loading: loadingAggregate,
    refetch,
  } = useQuery<ClientAggregateType>(CLIENT360AGGREGATES, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const {
    data: insuranceData,
    loading: loadingInsurance,
    refetch: refetchInsurance,
  } = useQuery<{ insurer_companies: InsuranceCompanyType[] }>(
    GETALLINSURANCECOMPANIES
  );

  const {
    data: borkerData,
    loading: loadingBroker,
    refetch: refetchbroker,
  } = useQuery<{ intemediary: IntermediariesBrokerType[] }>(
    GETALLINTERMEDIARIESBROKER,
    {
      variables: {
        company_id: localStorage.getItem("company_id"),
      },
    }
  );

  const {
    data: agentData,
    loading: loadingAgent,
    refetch: refetchAgent,
  } = useQuery<{ intemediary: IntermediariesAgentType[] }>(
    GETALLINTERMEDIARIESAGENT,
    {
      variables: {
        company_id: localStorage.getItem("company_id"),
      },
    }
  );

  const navigate = () => {
    history.push(`/dashboard/client`);
    window.location.reload();
  };

  const handleInsuranceSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleBrokerSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const handleAgentSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterInsuranceData = (filter: InsuranceCompanyType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.company_person?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.clientTypeByClientType?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const filterBrokerData = (filter: IntermediariesBrokerType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.intermediary_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.branch_code?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.bank_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.branch
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.nic_registration_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const filterAgentData = (filter: IntermediariesAgentType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.intermediary_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.branch_code?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.insurer_company?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.momo_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.bank_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.branch
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.nic_registration_number
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedInsuranceData = insuranceData?.insurer_companies
    ?.filter(filterInsuranceData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const paginatedBrokerData = borkerData?.intemediary
    ?.filter(filterBrokerData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const paginatedAgentData = agentData?.intemediary
    ?.filter(filterAgentData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (selectedfilter === "Agents") {
      if (
        (activePage - 1) * PAGE_SIZE <
        (agentData?.intemediary?.filter(filterAgentData).length || 0)
      ) {
        setActivePage(activePage + 1);
      }
    } else if (selectedfilter === "Insurance companies") {
      if (
        (activePage - 1) * PAGE_SIZE <
        (insuranceData?.insurer_companies?.filter(filterInsuranceData).length ||
          0)
      ) {
        setActivePage(activePage + 1);
      }
    }
    if (selectedfilter === "Brokers") {
      if (
        (activePage - 1) * PAGE_SIZE <
        (borkerData?.intemediary?.filter(filterBrokerData).length || 0)
      ) {
        setActivePage(activePage + 1);
      }
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onChangeFilter = (value: string) => {
    if (value === "Agents") {
      refetchAgent();
    } else if (value === "Insurance companies") {
      refetchInsurance();
    } else if (value === "Brokers") {
      refetchbroker();
    }
    setFilter(value);
  };

  const rowClick = (
    value:
      | InsuranceCompanyType
      | IntermediariesBrokerType
      | IntermediariesAgentType
      | undefined
  ) => {
    setSelectedRow(value);
    setOpen(true);
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <DetailDrawer
        onClose={() => setOpen(false)}
        open={open}
        title={
          selectedfilter === "Agents"
            ? "Agent Detail"
            : selectedfilter === "Brokers"
            ? "Broker Detail"
            : ""
        }
        children={
          selectedfilter === "Agents" ? (
            <AgentDetail
              content={selectedRow as IntermediariesAgentType | any}
            />
          ) : selectedfilter === "Brokers" ? (
            <BrokerDetail content={selectedRow as IntermediariesBrokerType} />
          ) : null
        }
        size={450}
      />
      <CustomHeader title="clients" />

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 md:lg:gap-3 lg:gap-5">
        <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.staff?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                staff
              </Typography>
            </div>
          </div>
        </Card>
        {/* <Card className="bg-brand-yellow rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.insurance_companies?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                insurer company
              </Typography>
            </div>
          </div>
        </Card> */}
        {/* <Card className="bg-brand-cyan rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.broker?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                Staff
              </Typography>
            </div>
          </div>
        </Card> */}
        <Card className="bg-brand-teal rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between text-center">
            <div>
              <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                {loadingAggregate ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.agent?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                Sub-agents
              </Typography>
            </div>
          </div>
        </Card>
      </div>

      <div className="mt-5 flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
          <div className="flex flex-row items-center w-full lg:w-1/2 mr-3">
            <Typography className="w-28">Filter by</Typography>
            <select
              value={selectedfilter}
              onChange={(e) => onChangeFilter(e.target.value)}
              className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected disabled>
                Select filter
              </option>
              {filterOptions?.map((item) => (
                <option
                  className="capitalize"
                  value={item}
                  key={item}
                  label={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full">
            <Input
              crossOrigin="anonymous"
              label="Search"
              value={searchText}
              onChange={
                selectedfilter === "Agents"
                  ? handleAgentSearch
                  : selectedfilter === "Brokers"
                  ? handleBrokerSearch
                  : handleInsuranceSearch
              }
              icon={<i className="fas fa-search text-base"></i>}
            />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              variant="h6"
              className="px-4 pb-4 font-bold leading-none opacity-70"
            >
              List of All {selectedfilter}
            </Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Role",
                  "Full Name",
                  "address",
                  "Email",
                  "TIN",
                  "Phone Number",
                  "Ghana Card",
                  "Momo Details",
                  "Bank Details",
                  "Branch and Division",
                  "",
                  //Will be a modal to show the Guarantors and Performance Information
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedBrokerData?.map((item, index: number) => {
                const isLast = index === paginatedBrokerData?.length - 1;
                const classes = isLast
                  ? "px-2 py-2  text-center"
                  : "px-2 py-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    onClick={() => rowClick(item)}
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.intermediary_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.name}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.phone_number}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.momo_details?.network}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col gap-2 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Bank:{" "}
                          <span className="font-normal">
                            {item?.bank_details?.bank_name}
                          </span>
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Branch:{" "}
                          <span className="font-normal">
                            {item?.bank_details?.branch}
                          </span>
                        </Typography>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loadingInsurance || loadingAgent || loadingBroker ? (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          ) : null}
        </CardBody>

        {paginatedAgentData || paginatedBrokerData || paginatedInsuranceData ? (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {selectedfilter === "Agents"
                    ? Math.ceil(
                        (agentData?.intemediary?.filter(filterAgentData)
                          .length || 0) / PAGE_SIZE
                      )
                    : selectedfilter === "Brokers"
                    ? Math.ceil(
                        (borkerData?.intemediary?.filter(filterBrokerData)
                          .length || 0) / PAGE_SIZE
                      )
                    : null}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextPage}
                disabled={
                  selectedfilter === "Agents"
                    ? activePage ===
                      Math.ceil(
                        (agentData?.intemediary?.filter(filterAgentData)
                          .length || 0) / PAGE_SIZE
                      )
                    : selectedfilter === "Brokers"
                    ? activePage ===
                      Math.ceil(
                        (borkerData?.intemediary?.filter(filterBrokerData)
                          .length || 0) / PAGE_SIZE
                      )
                    : false
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ClientView;
