import {
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
  Button,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import { DetailDrawer } from "../../../components";
import useSWR from "swr";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { AdminType, UpdateType } from "../../../types/admin";
import { UserType } from "../../../types/user";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { BiBlock } from "react-icons/bi";
import { GETALLPAYABLETAXES } from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import moment from "moment";

type BusinessClass = {
  name: string;
  id: string;
};

type RiskClass = {
  id: string;
  name: string;
};

type SubAgent = {
  id: string;
  last_name: string;
  first_name: string;
};

type CompanyStaff = {
  id: string;
  name: string;
};

type PolicyRequest = {
  cover_type: string;
  amount: number;
  businessClassByBusinessClass: BusinessClass;
  risk_class: RiskClass;
  sub_agent: SubAgent;
  company_staff: CompanyStaff;
};

type Policy = {
  policy_number: string;
  request: PolicyRequest;
};

export type TaxPayable = {
  amount: number;
  rate: number;
  id: string;
  created_at: string;
  commissions_payable: {
    policy: Policy;
    total_tax_deductions: number;
    commission_amount: number;
  };
  tax: {
    id: string;
    name: string;
  };
};

const PremiumView = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<UpdateType>();
  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  const PAGE_SIZE = 15;

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (admin: TaxPayable) => {
    const isTextMatch =
      admin?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.commissions_payable?.commission_amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.commissions_payable?.total_tax_deductions
        ?.toString()
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.commissions_payable?.policy?.policy_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.rate
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.tax?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const { data, loading, refetch } = useQuery<{
    tax_payable: TaxPayable[];
  }>(GETALLPAYABLETAXES, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const paginatedData = data?.tax_payable
    ?.filter(filterData)
    ?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.tax_payable?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);
      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <DetailDrawer
        size={350}
        title="detail"
        open={openDrawer}
        children={<>{/* <RenderTaxDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Total Taxes" />

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Total Taxes
              {/* {data?.admins && (
                      <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                        {data?.admins?.length}
                      </span>
                    )} */}
            </Typography>
          </div>
          <div className="w-full bg-white p-3">
            <Input
              crossOrigin="anonymous"
              label="Search"
              value={searchText}
              onChange={handleSearch}
              icon={<i className="fas fa-search text-base"></i>}
            />
          </div>

          <table className="w-full bg-white min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "amount",
                  "rate",
                  "policy number",
                  "business class",
                  "subclass",
                  "cover type",
                  "premium amount",
                  "created at",
                  "staff/agent",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item: any, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedRow(item);
                      // setModalOpen(true);
                    }}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS{" "}
                        {parseFloat(item?.amount ?? 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.rate} %
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.commissions_payable?.policy?.policy_number}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {
                          item?.commissions_payable?.policy?.request
                            ?.businessClassByBusinessClass?.name
                        }
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {
                          item?.commissions_payable?.policy?.request?.risk_class
                            ?.name
                        }
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.commissions_payable?.policy?.request?.cover_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS{" "}
                        {parseFloat(
                          item?.commissions_payable?.policy?.request?.amount ??
                            0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs "
                      >
                        {moment(
                          item?.commissions_payable?.policy?.request?.start_date
                        ).format("LL")}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col gap-2 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Full name:{" "}
                          <span className="font-normal">
                            {item?.request?.company_staff
                              ? `${item?.request?.company_staff?.name}`
                              : `${item?.request?.sub_agent?.first_name} ${item?.request?.sub_agent?.last_name}`}
                          </span>
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Phone number:{" "}
                          <span className="font-normal">
                            {item?.request?.company_staff
                              ? item?.request?.company_staff?.phone_number_one
                              : item?.request?.sub_agent?.phone_number}
                          </span>
                        </Typography>

                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          User type:{" "}
                          <span className="font-normal">
                            {item?.request?.company_staff
                              ? "Company Staff"
                              : "Sub Agent"}
                          </span>
                        </Typography>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {loading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {!paginatedData && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (data?.tax_payable?.filter(filterData).length || 0) /
                      PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (data?.tax_payable?.filter(filterData).length || 0) /
                      PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PremiumView;
