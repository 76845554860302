import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
  RouteProps,
  useHistory,
} from "react-router-dom";
// import { LoginEmail, Signup } from "./pages";
import { DashboardContent } from "./components";
import { ComponentType, useEffect, useState } from "react";
import LoginEmail from "./pages/login/email";
import Signup from "./pages/login/signup";
import { PaymentFailedPage, PaymentSuccessPage } from "./pages";

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

export function PrivateRoute({
  component: Component,
  ...rest
}: PrivateRouteProps) {
  const history = useHistory();

  const logoutUser = () => {
    const expiresAt = localStorage.getItem("expires_at");
    const currentTime = new Date().getTime();
    const expiresAtTime = parseInt(expiresAt as string, 10) * 1000;

    if (expiresAt && currentTime > expiresAtTime) {
      // Session has expired, clear user data and redirect to login
      localStorage.clear();
      history.push("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        logoutUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", logoutUser);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", logoutUser);
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" component={DashboardContent} />
        <Route path="/payment-success" component={PaymentSuccessPage} />
        <Route path="/payment-failed" component={PaymentFailedPage} />
        <Route path="/" component={LoginEmail} />
        <Route path="/signup" component={Signup} />
        {/* <Route path="/overview" component={Overview} /> */}
        {/* <Route path="brands" component={Brands} /> */}
        {/* <Route path="/games" component={GamePage} /> */}
      </Switch>
    </Router>
  );
}

export default App;
