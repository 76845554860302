import React, { useEffect, useState } from "react";
import { CustomDeleteModal, CustomModal } from "../../../components";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { DEFAULTPRIMARYCOLOR } from "../../../util";
import { UserType } from "../../../types/user";
import {
  GETPOLICY360,
  GETPOLICYFINANCIALTRANSACTION,
  GETPOLICYTECHNICALTRANSACTION,
  POLICYPERFORMANCE,
} from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import moment from "moment";
import { BiCheck } from "react-icons/bi";
import { MdCancel } from "react-icons/md";

type PolicyType = {
  id: string;
  policy_number: string | number;
  request: {
    policyHolder: {
      first_name: string;
      last_name: string;
    };
    risk_class: {
      id: string;
      name: string;
    };
    cover_type: string;
    businessClassByBusinessClass: {
      id: string;
      name: string;
    };
    end_date: string;
    start_date: string;
    insurerCompanyByInsurerCompany: {
      email: string;
      name: string;
      phone: string;
    };
    intemediary: {
      id: string;
      intermediary_type: string;
      name: string;
      phone_number: string;
    };
  };
};

type QuotationType = {
  amended_rated_details: any;
  amount: string;
  benefit_added_details: any;
  businessClassByBusinessClass: {
    name: string;
    id: string;
  };
  cover_type: string;
  created_at: string;
  duration: string;
  end_date: string;
  id: string;
  payment_channel: string;
  request_stage: string;
  sub_agent: {
    id: string;
    first_name: string;
    email: string;
    last_name: string;
  };
  company_staff: {
    email: string;
    id: string;
    phone_number_one: string;
  };
};

type PolicyTypeAggregate = {
  aggregate: {
    count: number;
  };

  commissions_payable_aggregate: {
    aggregate: {
      sum: {
        commission_amount: number;
        total_tax_deductions: number;
      };
    };
  };
};

type CommissionPaidType = {
  commision: {
    code: string;
    id: string;
    name: string;
  };
  commission_amount: string;
  date_created: string;
  date_updated: string;
  id: string;
  intermediary: any;
  rate: string;
  reference_amount: string;
  status: string;
  total_tax_deductions: string;
  user_type: string;
};

type TaxPaidType = {
  amount: string;
  created_at: string;
  id: string;
  rate: string;
  tax: {
    id: string;
    name: string;
  };
  commissions_payable: {
    commission_amount: string;
    reference_amount: string;
    status: string;
    user_type: string;
  };
  commission_payable_broker: {
    commission_amount: string;
    reference_amount: string;
    status: string;
    user_type: string;
  };
};

const Policies = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PolicyType>();
  const handleOpen = () => setOpen(!open);
  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();
  const PAGE_SIZE = 15;

  const { data, loading, refetch } = useQuery<{ policy: PolicyType[] }>(
    GETPOLICY360,
    {
      variables: {
        id: localStorage.getItem("company_id"),
      },
    }
  );

  const {
    data: PolicyAggregate,
    loading: loadingPolicyAggregate,
    refetch: refetchPolicyAggregate,
  } = useQuery<{ policy_aggregate: PolicyTypeAggregate }>(POLICYPERFORMANCE, {
    variables: {
      policy_id: selectedRow?.id,
    },
  });

  const {
    data: financialTransactions,
    loading: loadingFinancialTransactions,
    refetch: refetchFinancialTransactions,
  } = useQuery<{
    taxes_paid: TaxPaidType[];
    commissions_paid: CommissionPaidType[];
  }>(GETPOLICYFINANCIALTRANSACTION, {
    variables: {
      policy_id: selectedRow?.id,
    },
  });

  const {
    data: technicalTransactions,
    loading: loadingTechnicalTransactions,
    refetch: refetchTechnicalTransactions,
  } = useQuery<{
    saved_quoatations: QuotationType[];
    view_joined_policy_count_companies: {
      certificate_number: string;
      debit_number: string;
      policy_number: string;
      policy_stage: string;
      policy_type: string;
      reciept_number: string;
      status: boolean;
      total_policies: string;
      created_at: string;
      created_by: string;
    }[];
  }>(GETPOLICYTECHNICALTRANSACTION, {
    variables: {
      policy_id: selectedRow?.id,
      policy_number: selectedRow?.policy_number,
    },
  });

  const filterData = (filter: PolicyType) => {
    const isTextMatch =
      filter?.policy_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.policyHolder?.first_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.policyHolder?.last_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.intemediary?.intermediary_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.intemediary?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.intemediary?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.risk_class?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.insurerCompanyByInsurerCompany?.email
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.insurerCompanyByInsurerCompany?.phone
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.insurerCompanyByInsurerCompany?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.businessClassByBusinessClass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedData = data?.policy
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.policy?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleRowClick = (item: PolicyType) => {
    setSelectedRow(item);
    refetchPolicyAggregate();
    refetchFinancialTransactions({ variables: { policy_id: selectedRow?.id } });
    refetchTechnicalTransactions();
    setOpen(true);
  };

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);
      setColors(data.colors);
    }
  }, []);

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  return (
    <>
      <CustomModal
        open={!open}
        onClose={() => setOpen(false)}
        title="Policy Detail"
        className="h-full overflow-scroll scrolls"
        showClose
        size={"xl"}
        children={
          <>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-2 md:lg:gap-3 lg:gap-5">
              <Card className="bg-brand-gray-dark rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
                <div className="flex flex-row justify-between text-center">
                  <div>
                    <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                      {loadingPolicyAggregate ? (
                        <Spinner className="mb-2 text-white" />
                      ) : (
                        PolicyAggregate?.policy_aggregate?.aggregate?.count ?? 0
                      )}
                    </Typography>
                    <Typography className="text-sm uppercase font-normal text-white">
                      Total policy
                    </Typography>
                  </div>
                </div>
              </Card>

              <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
                <div className="flex flex-row justify-between text-center">
                  <div>
                    <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                      {loadingPolicyAggregate ? (
                        <Spinner className="mb-2 text-white" />
                      ) : (
                        <>
                          GHC{" "}
                          {(
                            (PolicyAggregate?.policy_aggregate
                              ?.commissions_payable_aggregate?.aggregate?.sum
                              ?.total_tax_deductions as any) ?? 0
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </Typography>
                    <Typography className="text-sm uppercase font-normal text-white">
                      Total tax deductions
                    </Typography>
                  </div>
                </div>
              </Card>

              <Card className="bg-brand-teal rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
                <div className="flex flex-row justify-between text-center">
                  <div>
                    <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
                      {loadingPolicyAggregate ? (
                        <Spinner className="mb-2 text-white" />
                      ) : (
                        <>
                          GHC{" "}
                          {(
                            (PolicyAggregate?.policy_aggregate
                              ?.commissions_payable_aggregate?.aggregate?.sum
                              ?.commission_amount as any) ?? 0
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                    </Typography>
                    <Typography className="text-sm uppercase font-normal text-white">
                      Commission amount
                    </Typography>
                  </div>
                </div>
              </Card>
            </div>

            <div className="mt-10">
              <h1 className="font-bold capitalize text-lg text-black mb-2">
                List of quoatations
              </h1>
              <CardBody className="shadow-sm overflow-x-scroll px-0 py-0">
                <table className=" w-full min-w-max table-auto">
                  <thead
                    className=""
                    style={{
                      background: `${
                        colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`,
                    }}
                  >
                    <tr>
                      {[
                        "cover type",
                        "payment channel",
                        "request stage",
                        "amount",
                        "duration",
                        "sub agent",
                        "company staff",
                        "Date created",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-normal text-sm  text-white  leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {technicalTransactions?.saved_quoatations?.map(
                      (item, index) => {
                        const isLast =
                          index ===
                          technicalTransactions?.saved_quoatations?.length - 1;
                        const classes = isLast
                          ? "p-2  text-center"
                          : "p-2 border-b-2 border-blue-gray-50 text-center";

                        return (
                          <tr
                            key={index.toString()}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.cover_type}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.payment_channel}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.request_stage}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                GHC{" "}
                                {parseInt(item?.amount).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.duration}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <div>
                                <Typography
                                  variant="small"
                                  className="text-left  capitalize"
                                  color="blue-gray"
                                >
                                  Name: {item?.sub_agent?.first_name}{" "}
                                  {item?.sub_agent?.last_name}
                                </Typography>
                                <Typography
                                  variant="small"
                                  className="text-left  capitalize"
                                  color="blue-gray"
                                >
                                  Email: {item?.sub_agent?.email}
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <div>
                                <Typography
                                  variant="small"
                                  className="text-left  capitalize"
                                  color="blue-gray"
                                >
                                  Email: {item?.company_staff?.email}
                                </Typography>
                                <Typography
                                  variant="small"
                                  className="text-left  capitalize"
                                  color="blue-gray"
                                >
                                  Phone number:{" "}
                                  {item?.company_staff?.phone_number_one}
                                </Typography>
                              </div>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.created_at).format("LLL")}
                              </Typography>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
            </div>

            <div className="mt-10">
              <h1 className="font-bold capitalize text-lg text-black mb-2">
                List of Policies
              </h1>
              <CardBody className="shadow-sm overflow-x-scroll px-0 py-0">
                <table className=" w-full min-w-max table-auto">
                  <thead
                    className=""
                    style={{
                      background: `${
                        colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`,
                    }}
                  >
                    <tr>
                      {[
                        "certificate number",
                        "debit number",
                        "policy number",
                        "policy stage",
                        "policy type",
                        "reciept number",
                        "status",
                        "total policies",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-normal text-sm  text-white  leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {technicalTransactions?.view_joined_policy_count_companies?.map(
                      (item, index) => {
                        const isLast =
                          index ===
                          technicalTransactions
                            ?.view_joined_policy_count_companies?.length -
                            1;
                        const classes = isLast
                          ? "p-2  text-center"
                          : "p-2 border-b-2 border-blue-gray-50 text-center";

                        return (
                          <tr
                            key={index.toString()}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.certificate_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.debit_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.policy_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.policy_stage}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.policy_type}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.reciept_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.status ? (
                                  <BiCheck className="text-lg ml-2 text-green" />
                                ) : (
                                  <MdCancel className="text-lg ml-2" />
                                )}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.total_policies}
                              </Typography>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
            </div>

            {loadingTechnicalTransactions ? (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            ) : null}

            <div className="mt-10">
              <h1 className="font-bold capitalize text-lg text-black mb-2">
                List of Taxes paid
              </h1>
              <CardBody className="shadow-sm overflow-x-scroll px-0 py-0">
                <table className=" w-full min-w-max table-auto">
                  <thead
                    className=""
                    style={{
                      background: `${
                        colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`,
                    }}
                  >
                    <tr>
                      {[
                        "tax",
                        "amount",
                        "rate",
                        "commission amount",
                        "reference amount",
                        "commission broker amount",
                        "commission broker reference amount",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-normal text-sm  text-white  leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {financialTransactions?.taxes_paid?.map((item, index) => {
                      const isLast =
                        index === financialTransactions?.taxes_paid?.length - 1;
                      const classes = isLast
                        ? "p-2  text-center"
                        : "p-2 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.tax?.name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize"
                            >
                              GHC{" "}
                              {parseInt(item?.amount).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.commissions_payable?.commission_amount}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {item?.commissions_payable?.reference_amount}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {
                                item?.commission_payable_broker
                                  ?.commission_amount
                              }
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs"
                            >
                              {
                                item?.commission_payable_broker
                                  ?.reference_amount
                              }
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
            </div>

            <div className="mt-10">
              <h1 className="font-bold capitalize text-lg text-black mb-2">
                List of commissions paid
              </h1>
              <CardBody className="shadow-sm overflow-x-scroll px-0 py-0">
                <table className=" w-full min-w-max table-auto">
                  <thead
                    className=""
                    style={{
                      background: `${
                        colors?.length! > 1
                          ? `${colors![1]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`,
                    }}
                  >
                    <tr>
                      {[
                        "commision",
                        "commission amount",
                        "rate",
                        "reference amount",
                        "total tax deductions",
                        "status",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-normal text-sm  text-white  leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {financialTransactions?.commissions_paid?.map(
                      (item, index) => {
                        const isLast =
                          index ===
                          financialTransactions?.commissions_paid?.length - 1;
                        const classes = isLast
                          ? "p-2  text-center"
                          : "p-2 border-b-2 border-blue-gray-50 text-center";

                        return (
                          <tr
                            key={index.toString()}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.commision?.name}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GHC{" "}
                                {parseInt(
                                  item?.commission_amount as any
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.rate}%
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                GHC{" "}
                                {parseInt(
                                  item?.reference_amount as any
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                GHC{" "}
                                {parseInt(
                                  item?.total_tax_deductions as any
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs"
                              >
                                {item?.status ? (
                                  <BiCheck className="text-lg ml-2 text-green" />
                                ) : (
                                  <MdCancel className="text-lg ml-2" />
                                )}
                              </Typography>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </CardBody>
            </div>

            {loadingFinancialTransactions ? (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-10 h-10" />
              </div>
            ) : null}
          </>
        }
      />
      <CustomHeader title="policies" />
      <div className="w-full">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4 font-bold leading-none opacity-70 capitalize"
            >
              List of All Policies
              {data?.policy && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.policy?.length}
                </span>
              )}
            </Typography>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "policy number",
                  "policy Holder",
                  "risk class",
                  "cover type",
                  "Intermediary Information",
                  "Date of First Inception",
                  "",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.policy_number}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {`${item?.request?.policyHolder?.first_name} ${item?.request?.policyHolder?.last_name}`}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.request?.risk_class?.name}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.request?.cover_type}
                      </Typography>
                    </td>
                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <div>
                        <Typography
                          variant="small"
                          className="text-left  capitalize"
                          color="blue-gray"
                        >
                          Name: {item?.request?.intemediary?.name}
                        </Typography>
                        <Typography
                          variant="small"
                          className="text-left  capitalize"
                          color="blue-gray"
                        >
                          Phone number:{" "}
                          {item?.request?.intemediary?.phone_number}
                        </Typography>
                      </div>
                    </td>

                    <td
                      className={classes}
                      onClick={() => handleRowClick(item)}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.request?.start_date).format("LLL")}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Button
                        onClick={() => handleRowClick(item)}
                        variant="filled"
                        color="green"
                        className="font-normal capitalize"
                      >
                        View more
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {!paginatedData && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography variant="h6" className="text-white">
              No data found
            </Typography>
          </div>
        )}

        {loading ? (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        ) : null}

        {/* Paginator */}
        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="white" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (data?.policy?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (data?.policy?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Policies;
