import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import HeaderIcon from "./HeaderIcon";
import { UserType } from "../../types/user";
import { DEFAULTPRIMARYCOLOR } from "../../util";

export const CustomHeader = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => {
  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <div className="sticky top-0 z-50 mb-5">
      <div className="bg-[#222020] py-3 sticky top-0 z-50 w-full flex flex-row items-center justify-between">
        <div className="">
          <Typography
            style={{
              color:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="capitalize font-semibold text-lg lg:text-xl"
          >
            {title}
          </Typography>
          {description && (
            <span className="text-sm text-white">{description}</span>
          )}
        </div>
        <HeaderIcon />
      </div>
    </div>
  );
};
