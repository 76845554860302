import { Button, Input, Spinner, Typography } from "@material-tailwind/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BASEURL } from "../../../util";
import Logo from "../../../assets/logo.png";
import { GET_ROLE_PERMISSIONS } from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";

type LoginType = {
  email: string;
  password: string;
};

export default function LoginEmail() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<LoginType>();

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    try {
      let options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ ...data, usertype: "company_administrator" }),
      };

      const req = await fetch(`${BASEURL}/broker-login`, options);

      const res = await req.json();

      if (req.ok) {
        if (res?.access_token) {
          const { data } = res;

          localStorage.setItem("usertype", "company_administrator");
          localStorage.setItem("role_id", data?.role_id);
          localStorage.setItem("company_id", data?.company_id);
          localStorage.setItem("token", res?.access_token);
          localStorage.setItem("expires_at", res?.expires_at);

          localStorage.setItem("user", JSON.stringify(data));
          history.push("/dashboard");

          window.location.reload();
        } else {
          toast.error(res?.data ?? "An error occured");
        }
      } else {
        toast.error(res?.data ?? "An error occured");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  return (
    <main className="w-full h-screen bg-black grid grid-cols-1 lg:grid-cols-2">
      <div className="h-full bg-white flex flex-col px-5 md:px-0 lg:px-0  lg:items-center md:lg:items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="mb-10 flex items-center justify-center">
            <img src={Logo} alt="logo" className="w-36 object-cover" />
          </div>
          <p className="mb-4 text-grey-700 font-bold">Login to your account</p>

          <div className="my-5" />
          <Input
            crossOrigin={""}
            id="email"
            error={!!errors.email}
            type="email"
            label="Email"
            size="lg"
            className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className="text-left mt-2 text-xs text-brand-primary">
              Email is required
            </span>
          )}
          {/* {usertype === "company_administrator" ? (
            <>
              <Input
                crossOrigin={""}
                id="email"
                error={!!errors.email}
                type="email"
                label="Email"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-left mt-2 text-xs text-brand-primary">
                  Email is required
                </span>
              )}
            </>
          ) : (
            <>
              <Input
                crossOrigin={""}
                id="Username"
                error={!!errors.email}
                type="Username"
                label="Username"
                size="lg"
                className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="text-left mt-2 text-xs text-brand-primary">
                  Username is required
                </span>
              )}
            </>
          )} */}

          <div className="my-6" />

          <Input
            crossOrigin={""}
            size="lg"
            id="password"
            type="password"
            error={!!errors.password}
            label="Enter a password"
            className="bg-gray-100 flex items-center w-full px-5 py-4 mr-2 text-sm font-medium outline-none focus:bg-grey-400 placeholder:text-grey-700 bg-grey-200 text-dark-grey-900 rounded-2xl"
            {...register("password", { required: true })}
          />
          {errors.password && (
            <span className="text-left mt-2 text-xs text-brand-primary">
              Password is required
            </span>
          )}

          <Button
            variant="gradient"
            size="lg"
            type="submit"
            style={{
              background: "#ED7C31",
            }}
            className="mt-10 flex items-center justify-center w-full text-sm font-bold leading-none text-white transition duration-300 md:w-96 rounded-2xl hover:bg-purple-blue-600 focus:ring-4 focus:ring-purple-blue-100 bg-purple-blue-500"
          >
            {isSubmitting ? (
              <Spinner className="w-5 h-5 text-white" />
            ) : (
              "Sign In"
            )}
          </Button>
          {/* <p className="text-sm leading-relaxed text-grey-900">
                Don't have an account?{" "}
                <a
                  href="javascript:void(0)"
                  className="font-bold text-grey-700"
                >
                  Create an Account
                </a>
              </p> */}
        </form>
      </div>
      <div className="hidden lg:flex h-full flex-col items-center justify-center text-center">
        <img
          className="w-96"
          src="https://www.datapine.com/images/dashboards-different-devices.png"
        />
        <Typography className="mt-10 w-52 text-sm font-semibold text-white">
          Your number one insurance company
        </Typography>
      </div>
    </main>
  );
}
