import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  GETALLAGENTS,
  GETALLBRANCHES,
  GETALLDIVISIONSORWORKGROUPS,
  GETALLFUNDSREQUEST,
  GETBALANCEOFUSERS,
  GETCOMPANYROLES,
  GETCOMPANYSTAFF,
} from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  BASEURL,
  DEFAULTPRIMARYCOLOR,
  intermediary_types,
} from "../../../util";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import { DASHBOARD_USERTYPE, parameter_type } from "../../../types/role";
import { toast } from "react-toastify";
import { UserType } from "../../../types/user";

export type AgentType = {
  first_name: string;
  last_name: string;
  phone_number: string;
  role_id: string;
  nic_registration_number: string;
  is_first_time: string;
  intermediary_type: string;
  balance: string;
  company_id: string;
  agent_id: string;
  agent_code: string;
  active_status: string;
};

type FormType = {
  branch_id: {
    id: string;
    amount: string;
  };
  division_id: {
    id: string;
    amount: string;
  };
  agent_id: {
    id: string;
    amount: string;
  };
  staff_id: {
    id: string;
    amount: string;
  };
  role_id: {
    id: string;
    amount: string;
  };
  usertype: string;
};

export type FundsType = {
  amount: string;
  branch_name: string;
  company_role_name: string;
  parameter_id: string;
  company_staff_name: string;
  division_name: string;
  funds_request_id: string;
  status: string;
  //   agent: sub_agent_name;
  parameter_type: string;
  agent_cash_limit: string;
  staff_cash_limit: string;
  agent_balance: string;
  branch_balance: string;
  division_balance: string;
  role_balance: string;
  staff_balance: string;
  agent: string;
  created_at: string;
};

type BalanceType = {
  name: string;
  balance: string;
};

const AccountFunding = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchFundsText, setSearchFundsText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [activePage2, setActivePage2] = useState(1);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormType>();

  const userType = watch("usertype");

  let PAGE_SIZE = 11;

  const { data: divisions, loading: loadingDivisions } = useQuery<{
    divisions: {
      id: string;
      name: string;
    }[];
  }>(GETALLDIVISIONSORWORKGROUPS, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const { data: staffs, loading: loadingStaff } = useQuery<{
    company_staff: {
      id: string;
      name: string;
    }[];
  }>(GETCOMPANYSTAFF, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const { data: roles, loading: loadingRoles } = useQuery<{
    company_role: {
      created_at: string;
      description: string;
      id: string;
      name: string;
    }[];
  }>(GETCOMPANYROLES, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const { data: agents, loading: loadingAgents } = useQuery<{
    agents: AgentType[];
  }>(GETALLAGENTS, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const {
    data: balances,
    loading: loadingBalances,
    refetch: refetchBalance,
  } = useQuery<{
    positive_balance_view: BalanceType[];
  }>(GETBALANCEOFUSERS);

  const {
    data: funds,
    loading: loadingFunds,
    refetch: refchFunds,
  } = useQuery<{
    view_fund_account_details: FundsType[];
  }>(GETALLFUNDSREQUEST, {
    "variables": {
      company_id: localStorage.getItem("company_id")
    }
  });

  const {
    data: branches,
    loading,
    refetch,
  } = useQuery<{
    branch: {
      address: string;
      code: string;
      name: string;
      id: string;
    }[];
  }>(GETALLBRANCHES);

  const handleFundSearch = (e: any) => {
    setSearchFundsText(e.target.value);
    setActivePage(1);
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: FundsType) => {
    const isTextMatch =
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.branch_name
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.division_name
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.role_balance
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.company_staff_name
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.status?.toLowerCase()?.includes(searchFundsText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchFundsText?.toLowerCase());

    return isTextMatch;
  };

  const filterBalance = (filter: BalanceType) => {
    const isTextMatch = filter?.name
      ?.toLowerCase()
      ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedBalanceData = balances?.positive_balance_view
    ?.filter(filterBalance)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const paginatedFundsData = funds?.view_fund_account_details
    ?.filter(filterData)
    .slice((activePage2 - 1) * PAGE_SIZE, activePage2 * PAGE_SIZE);

  const nextFundsPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (funds?.view_fund_account_details?.filter(filterData).length || 0)
    ) {
      setActivePage2(activePage2 + 1);
    }
  };

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (balances?.positive_balance_view?.filter(filterBalance).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
    if (activePage2 > 1) {
      setActivePage2(activePage2 - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      let payload = {
        agent_id: {
          id: data?.agent_id?.id ?? "",
          amount: data?.agent_id?.amount ?? "",
        },
        staff_id: {
          id: data?.staff_id?.id ?? "",
          amount: data?.staff_id?.amount ?? "",
        },
        usertype: DASHBOARD_USERTYPE.company,
      };

      // let payload = {
      //   branch_id: {
      //     id: "00000000-0000-0000-0000-000000000000",
      //     amount: "0",
      //   },
      //   division_id: {
      //     id: "00000000-0000-0000-0000-000000000000",
      //     amount: "0",
      //   },
      //   agent_id: {
      //     id: "00000000-0000-0000-0000-000000000000",
      //     amount: "0",
      //   },
      //   staff_id: {
      //     id: "00000000-0000-0000-0000-000000000000",
      //     amount: "0",
      //   },
      //   role_id: {
      //     id: "00000000-0000-0000-0000-000000000000",
      //     amount: "0",
      //   },
      //   usertype: DASHBOARD_USERTYPE.company,
      // };

      const res = await fetch(`${BASEURL}/fund-users-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      const req = await res.json();

      if (res.ok) {
        toast.success(req?.message ?? "Account funded successfully");
        reset();
        refchFunds();
        refetchBalance();
      } else {
        toast.error(req?.detail ?? req?.message);
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to fund account");
    }
  };

  return (
    <>
      <CustomHeader title="Account funding" />

      <div className="">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" rounded-lg overflow-hidden w-full"
          >
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="p-3"
            >
              <Typography className="text-base text-white">
                Fund Account
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    {/* <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        User type
                      </Typography>
                      <select
                        {...register("usertype", { required: true })}
                        className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                      >
                        {["agent", "staff"]?.map((type) => (
                          <option key={type} label={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div> */}

                    <div className="flex flex-col">
                      <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Agent
                        </Typography>

                        <div className="flex flex-row items-center gap-2 w-full">
                          <select
                            {...register("agent_id.id")}
                            className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          >
                            <option value="">Select agent</option>
                            {loadingAgents ? (
                              <option disabled>Loading...</option>
                            ) : (
                              agents?.agents?.map((agent) => (
                                <option
                                  key={agent?.agent_id}
                                  label={`${agent?.first_name} ${agent?.last_name ? agent?.last_name : ""
                                    }`}
                                  value={agent?.agent_id}
                                >
                                  {agent?.last_name ? agent?.last_name : ""}
                                </option>
                              ))
                            )}
                          </select>

                          <Input
                            className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                            crossOrigin="anonymous"
                            label="Amount"
                            {...register("agent_id.amount")}
                          />
                        </div>
                      </div>
                      <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Staff
                        </Typography>

                        <div className="flex flex-row items-center gap-2 w-full">
                          <select
                            {...register("staff_id.id")}
                            className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          >
                            <option value={""}>
                              {loadingStaff ? "Loading..." : "Select staff"}
                            </option>
                            {staffs?.company_staff?.map((staff) => (
                              <option
                                key={staff?.id}
                                label={staff?.name}
                                value={staff?.id}
                              >
                                {staff?.name}
                              </option>
                            ))}
                          </select>
                          <Input
                            className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                            crossOrigin="anonymous"
                            label="Amount"
                            {...register("staff_id.amount")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                      {/* <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Branch
                      </Typography> */}
                      <div className="flex flex-row items-center gap-2 w-full">
                        {/* <select
                          {...register("branch_id.id", { required: true })}
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                        >
                          <option>
                            {loading ? "Loading..." : "Select branch"}
                          </option>
                          {branches?.branch?.map((branch, index) => (
                            <option
                              key={branch.id}
                              label={`${branch?.name} (${branch?.code})`}
                              value={branch?.id}
                            >
                              {branch?.name} (${branch?.code})
                            </option>
                          ))}
                        </select> */}
                        {/* <Input
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          crossOrigin="anonymous"
                          label="Amount"
                          {...register("branch_id.amount", { required: true })}
                        /> */}
                      </div>
                    </div>
                    <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                      {/* <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Division
                      </Typography> */}
                      <div className="flex flex-row items-center gap-2 w-full">
                        {/* <select
                          {...register("division_id.id", { required: true })}
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                        >
                          <option>
                            {loadingDivisions
                              ? "Loading..."
                              : "Select division"}
                          </option>
                          {divisions?.divisions?.map((division) => (
                            <option
                              key={division?.id}
                              label={division?.name}
                              value={division?.id}
                            >
                              {division?.name}
                            </option>
                          ))}
                        </select> */}
                        {/* <Input
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          crossOrigin="anonymous"
                          label="Amount"
                          {...register("division_id.amount", {
                            required: true,
                          })}
                        /> */}
                      </div>
                    </div>
                    <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                      {/* <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Role
                      </Typography> */}
                      <div className="flex flex-row items-center gap-2 w-full">
                        {/* <select
                          {...register("role_id.id", { required: true })}
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                        >
                          <option>
                            {loadingRoles ? "Loading..." : "Select role"}
                          </option>
                          {roles?.company_role?.map((role) => (
                            <option
                              key={role?.id}
                              label={role?.name}
                              value={role?.id}
                            >
                              {role?.name}
                            </option>
                          ))}
                        </select> */}
                        {/* <Input
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          crossOrigin="anonymous"
                          label="Amount"
                          {...register("role_id.amount", { required: true })}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end mt-5">
              <Button
                type="submit"
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="flex flex-row items-center  font-normal"
              >
                <BiPlus size={20} />

                {isSubmitting ? (
                  <Spinner className="w-4 h-4" />
                ) : (
                  "Create Funding"
                )}
              </Button>
            </div>
          </form>

          <div className=" rounded-lg overflow-hidden w-full">
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="p-3"
            >
              <Typography className="text-base text-white">
                Account balances
              </Typography>
            </div>
            <div className="py-3 bg-white rounded-b-xl">
              <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
                <div className="p-3">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      value={searchText}
                      onChange={handleFundSearch}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
                <table className=" bg-white w-full min-w-max table-auto text-center">
                  <thead className="">
                    <tr>
                      {["account", "balance"].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedBalanceData?.map((item, index: number) => {
                      const isLast = index === paginatedBalanceData?.length - 1;
                      const classes = isLast
                        ? "px-4 py-3  text-center"
                        : "px-4 py-3 border-b-2 border-blue-gray-50 text-center";

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item?.name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              GHC{" "}
                              {parseInt(item?.balance).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>

              {loadingBalances && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}

              {!paginatedBalanceData && !loadingBalances && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="black" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {paginatedBalanceData && (
                <div className="my-10 flex items-center justify-center">
                  <div className="flex items-center gap-8">
                    <IconButton
                      size="sm"
                      variant="outlined"
                      onClick={prevPage}
                      disabled={activePage === 1}
                    >
                      <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                    </IconButton>
                    <Typography color="gray" className="font-normal">
                      Page{" "}
                      <strong className="text-gray-900">{activePage}</strong> of{" "}
                      <strong className="text-gray-900">
                        {Math.ceil(
                          (balances?.positive_balance_view?.filter(
                            filterBalance
                          ).length || 0) / PAGE_SIZE
                        )}
                      </strong>
                    </Typography>
                    <IconButton
                      size="sm"
                      variant="outlined"
                      onClick={nextPage}
                      disabled={
                        activePage ===
                        Math.ceil(
                          (balances?.positive_balance_view?.filter(
                            filterBalance
                          ).length || 0) / PAGE_SIZE
                        )
                      }
                    >
                      <AiOutlineArrowRight
                        strokeWidth={2}
                        className="h-4 w-4"
                      />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-10 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">
              List of All Account Funding Requestssss
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchFundsText}
                    onChange={handleFundSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "No",
                      "Account",
                      "Amount",
                      "Status",
                      // "Bank Details",
                      // "Momo Details",
                      // "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedFundsData?.map((item, index: number) => {
                    const isLast = index === paginatedFundsData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-3  text-left"
                      : "px-4 py-3 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.parameter_type === parameter_type.agent_id
                              ? item?.agent
                              : item?.parameter_type === parameter_type.staff_id
                                ? item?.company_staff_name
                                : item?.parameter_type ===
                                  parameter_type?.branch_id
                                  ? item?.branch_name
                                  : item?.parameter_type ===
                                    parameter_type?.division_id
                                    ? item?.division_name
                                    : item?.company_role_name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            GHC{" "}
                            {parseInt(item?.amount).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="font-semibold text-xs capitalize "
                            color={
                              item?.status === "approve"
                                ? "green"
                                : item?.status === "pending"
                                  ? "blue"
                                  : "red"
                            }
                          >
                            {item?.status === "approve"
                              ? "approved"
                              : item?.status === "pending"
                                ? "pending"
                                : "disapproved"}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loadingFunds && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedFundsData && !loadingFunds && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {paginatedFundsData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage2 === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page{" "}
                    <strong className="text-gray-900">{activePage2}</strong> of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (funds?.view_fund_account_details?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextFundsPage}
                    disabled={
                      activePage2 ===
                      Math.ceil(
                        (funds?.view_fund_account_details?.filter(filterData)
                          .length || 0) / PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default AccountFunding;
