import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { SubClassType } from "../business-class/manage";
import { GETBUSINESSCLASSES, GETSUBCLASSES } from "../../../GraphQl/queries";
import { BusinessClassType } from "../../../types/class";
import { DELETECOMMISSIONRATE } from "../../../GraphQl/mutations";
import {
  BASEURL,
  DEFAULTPRIMARYCOLOR,
  intermediary_types,
} from "../../../util";
import { DASHBOARD_USERTYPE } from "../../../types/role";
import { UserType } from "../../../types/user";

type FormType = {
  class: string;
  subclass: string;
  cover: string;
  rate: string;
  intermediary: string;
  cover_type: string;
  description: string;
};

type RateType = {
  id: string;
  class: string;
  subclass: string;
  user_type: string;
  rate: string;
  description: string;
  cover_type: string;
};

const ManageComissionRate = () => {
  const getStorage = localStorage.getItem("commissions");
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [selectedCommission, setCommission] = useState<{
    id: string;
    name: string;
    description: string;
  }>();

  const [loading, setLoading] = useState(true);
  const [commisions_rates, setCommissionRates] = useState<RateType[]>();

  const PAGE_SIZE = 10;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormType>();

  const watchClass = watch("class");

  const {
    data: subclasses,
    loading: loadingSubClass,
    refetch: refetchSubClass,
  } = useQuery<{
    subclass: SubClassType[];
  }>(GETSUBCLASSES, {
    variables: {
      class_id: watchClass,
    },
  });

  const {
    data: classes,
    refetch: refreshClass,
    loading: loadingClasses,
  } = useQuery<{
    business_classes: BusinessClassType[];
  }>(GETBUSINESSCLASSES);

  const fetchRates = async () => {
    const storedDataString = localStorage.getItem("commissions")!;
    const comData = JSON.parse(storedDataString);

    try {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          company_id: localStorage.getItem("company_id"),
          commission_id: comData.id,
        }),
      };

      const req = await fetch(`${BASEURL}/get-commissions-rates`, options);

      const res = await req.json();

      if (req.ok) {
        if (res?.data) {
          setCommissionRates(res?.data);
        } else {
          toast.error(res?.message ?? res?.detail);
        }
      } else {
        toast.error(res?.message ?? "An error occured");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const [DeleteCommissionRate] = useMutation(DELETECOMMISSIONRATE, {
    onCompleted: (data) => {
      toast.success("Commission rate deleted successfully");
      fetchRates();
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error deleting commission rate"),
  });

  const filterData = (filter: RateType) => {
    const isTextMatch =
      filter?.class?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.description?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.rate?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.subclass?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.user_type?.toLowerCase()?.includes(searchText?.toLowerCase());

    return isTextMatch;
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  // Filtered and paginated data for the current page
  const paginatedData = commisions_rates
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (commisions_rates?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      let payload = JSON.stringify({
        company_id: localStorage.getItem("company_id"),
        subclass_id: data?.subclass,
        commission_id: selectedCommission?.id,
        cover_type: data?.cover_type,
        class_id: data?.class,
        rate: data?.rate,
        description: data?.description,
        intermediary_type: data?.intermediary,
        usertype: DASHBOARD_USERTYPE.company,
      });

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: payload,
      };

      const req = await fetch(`${BASEURL}/add-commissions-rates`, options);

      const res = await req.json();

      if (req.ok) {
        toast.success(
          res?.message ?? res?.detail ?? "Commission rate Successfully added"
        );
        reset();
        fetchRates();
        refreshClass();
      } else {
        const errorResponse = await req.json().catch(() => ({}));
        const errorMessage = errorResponse;
        if (errorMessage === "{}") {
          toast.error(
            "Usertype already has a commission rate for this subclass selected"
          );
        } else {
          toast.error("An error occurred");
        }
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating commission rate");
    }
  };

  const deleteCommissionRate = (id: string) => {
    DeleteCommissionRate({
      variables: {
        id,
      },
    });
    try {
    } catch (error: any) {
      toast.error(error?.message ?? "Error deleting commission rate");
    }
  };

  useEffect(() => {
    if (getStorage) {
      setCommission(JSON.parse(getStorage));
    }
  }, []);

  useEffect(() => {
    fetchRates();
  }, []);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Commission rates" />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Type of Commission", "Description"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedCommission?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedCommission?.description}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              Create Commission Rate
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Class of Business
                    </Typography>

                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                    >
                      <option>
                        {loadingClasses ? "Loading..." : "Select class"}
                      </option>
                      {classes?.business_classes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Subclass
                    </Typography>

                    <select
                      id="subclass"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("subclass", { required: true })}
                    >
                      <option>
                        {loadingSubClass ? "Loading..." : "Select subclass"}
                      </option>
                      {subclasses?.subclass?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                      <option
                        value={"00000000-0000-0000-0000-000000000000"}
                        label="All"
                      >
                        All
                      </option>
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      User/ Intermediary Type
                    </Typography>
                    <select
                      id="intermediary"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("intermediary", { required: true })}
                    >
                      {intermediary_types
                        ?.slice(0, 3)
                        ?.map((intermediary_type) => (
                          <option
                            label={intermediary_type?.name}
                            value={intermediary_type?.code}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))}

                      <option
                        label={"All"}
                        value={"00000000-0000-0000-0000-000000000000"}
                      >
                        All
                      </option>
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Cover Type
                    </Typography>
                    <select
                      id="cover_type"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("cover_type", { required: true })}
                    >
                      {[
                        { name: "Comprehensive", code: "COMP" },
                        { name: "Third Party Only", code: "TP" },
                        { name: "Third Party Fire and Theft", code: "TFT" },
                        { name: "All", code: "All" },
                      ]?.map((intermediary_type) => (
                        <option
                          label={intermediary_type?.name}
                          value={intermediary_type?.name}
                        >
                          {intermediary_type?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("rate", { required: true })}
                    />
                  </div>

                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: false })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                type="submit"
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="capitalize flex flex-row items-center  font-normal"
              >
                <BiPlus size={20} />
                {isSubmitting ? "Creating..." : "Create commission Rate"}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">
              List of {selectedCommission?.name} Rates
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {[
                      "no",
                      "class",
                      "Subclass",
                      "Description",
                      "covertype",
                      "User type",
                      "Rate",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left";

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.class}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.subclass}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.description}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.cover_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.user_type ===
                            "00000000-0000-0000-0000-000000000000"
                              ? "All users"
                              : item?.user_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.rate}%
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <IconButton
                              onClick={() => deleteCommissionRate(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (commisions_rates?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (commisions_rates?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManageComissionRate;
