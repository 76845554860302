import {
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { BASEURL, calculateDaysLeft } from "../../../util";
import useSWR from "swr";
import {
  CustomBarChart,
  CustomDeleteModal,
  CustomPieChart,
  DetailDrawer,
  HeaderIcon,
  RenderDetail,
} from "../../../components";
import { CampaignType } from "../../../types/campaign";
import { UserType } from "../../../types/user";
import dayjs from "dayjs";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { RiAdminLine, RiCustomerService2Line } from "react-icons/ri";
import { MdOutlinePolicy } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa6";
import { TbPencilDiscount } from "react-icons/tb";
import {
  BROKERCOMPANYDASHBOARD,
  INSURANCECOMPANYDASHBOARD,
} from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { InsuranceDahboardType } from "../../../types/query";
import CustomVolumeBarChart, {
  CustomPremiumNumberBarChart,
} from "../../../components/charts/barChart";

function DashboardOverview() {
  const { data, loading, refetch } = useQuery<InsuranceDahboardType>(
    BROKERCOMPANYDASHBOARD,
    {
      variables: {
        id: localStorage.getItem("company_id"),
      },
    }
  );

  const {
    data: insurance_companies_graph_data,
    isLoading,
    error,
    mutate,
  } = useSWR<{
    data: {
      premiums_per_business_class: Array<{
        name: string;
        total_premium: number;
      }>;
      premiums_number_per_business_class: Array<{
        name: string;
        record_count: number;
      }>;
      volumes_generated_by_staff: Record<string, number>;
    };
  }>(`${BASEURL}/brokers_graph_data`, () =>
    fetch(`${BASEURL}/brokers_graph_data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        broker_id: localStorage.getItem("company_id"),
      }),
    }).then((res) => res.json())
  );

  return (
    <>
      <CustomHeader title="Overview" />
      <div className="w-full">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 md:lg:gap-4 lg:gap-5">
          <Card className="bg-brand-purple rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.company_staff_aggregate?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  staff
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.total_policy_holders?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Policyholders
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.sub_agents_aggregate?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Sub agents
                </Typography>
              </div>
              <RiAdminLine size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-[#003566] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.active_policy?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Active Policies
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-[#004e98] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.new_policy?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  New Policies
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-[#b1740f] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.renewed_policy?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Renewed Policies
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-[#403d39] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    data?.lapse_policy?.aggregate?.count
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Lapsed Policies
                </Typography>
              </div>
              <TbPencilDiscount size={28} color="#cbcbcb" />
            </div>
          </Card>

          <Card className="bg-brand-cyan rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.pending_commissions?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  pending commissions
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-brand-cyan rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.paid_commissions?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  paid commissions
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card>
          <Card className="bg-red-400 rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.premium_paid[0]?.total_amount_sold as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Premiums Paid
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card>
          {/* <Card className="bg-brand-green rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.premium_outstanding[0]
                        ?.total_amount_sold as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Premiums Outstanding
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card> */}
          {/* <Card className="bg-brand-gray rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.all_commissions?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Commissions
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card> */}
          {/* <Card className="bg-[#451e31] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.commission_paid?.aggregate?.sum?.amount as any) ??
                      0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Commissions Paid
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card> */}
          {/* <Card className="bg-[#abc4ff] rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
            <div className="flex flex-row justify-between">
              <div>
                <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                  {loading ? (
                    <Spinner className="mb-2 text-white" />
                  ) : (
                    "GH₵" +
                    (
                      (data?.outstanding_commissions?.aggregate?.sum
                        ?.commission_amount as any) ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
                </Typography>
                <Typography className="text-sm uppercase font-normal text-white">
                  Commissions Outstanding
                </Typography>
              </div>
              <FaMoneyBillWave size={28} color="#cbcbcb" />
            </div>
          </Card> */}
        </div>

        <div className="mt-6 w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 md:lg:gap-4 lg:gap-5"></div>
      </div>

      <div className="grid gap-6 my-7 grid-cols-1 lg:grid-cols-2">
        <div className="rounded-xl bg-white py-3">
          <span className="text-sm px-3 mb-4 font-semibold">
            Premiums per Class of Business
          </span>
          <div className="h-96 w-full mt-10 flex items-center justify-center">
            {isLoading ? (
              <Spinner />
            ) : (
              <CustomBarChart
                data={
                  insurance_companies_graph_data?.data
                    ?.premiums_per_business_class as any
                }
              />
            )}
          </div>
        </div>

        <div className="rounded-xl bg-white py-3">
          <span className="text-sm px-3 mb-4 font-semibold">
            Premium number per class
          </span>
          <div className="h-96 w-full mt-10 flex items-center justify-center">
            {isLoading ? (
              <Spinner />
            ) : (
              <CustomPremiumNumberBarChart
                data={
                  insurance_companies_graph_data?.data
                    ?.premiums_number_per_business_class as any
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="grid gap-6 my-7 grid-cols-1 lg:grid-cols-1 "></div>

      <div className="grid gap-6 my-7 grid-cols-1 lg:grid-cols-1 ">
        <div className="rounded-xl bg-white p-3 col-span-2">
          <span className="text-sm p-3 mb-4 font-semibold">
            {/* Volumes generated by Brokers, Agents and Direct Business */}
            Volumes generated by staff
          </span>
          <div className="h-96 w-full mt-10 p-0">
            <CustomVolumeBarChart
              data={
                insurance_companies_graph_data?.data
                  ?.volumes_generated_by_staff as any
              }
            />
          </div>
        </div>

        {/* <div className="rounded-xl bg-white p-3">
          <span className="text-sm p-3 mb-4 font-semibold">
            Volumes generated by Branches and Divisions
          </span>
          <div className="h-96 w-full mt-10 flex items-center justify-center">
            {isLoading ? (
              <Spinner />
            ) : (
              <CustomPieChart
                data={
                  insurance_companies_graph_data?.premiums_per_business_class as any
                }
              />
            )}
          </div>
        </div> */}
      </div>

      {/* <Card className="rounded-xl w-full overflow-x-scroll">
        <div className="w-full px-5 flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center gap-3 py-4 rounded-txl bg-black">
          <Typography className="text-base text-brand-primary font-semibold">
            Top performing agents
          </Typography>
          <select
            id="filters"
            className="h-10 w-full md:w-1/4 lg:w-1/4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option className="" selected disabled>
              Filter by
            </option>
          </select>
        </div>
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="capitalize font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(
              ({ name, phone, location, total_policy_sold }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={name}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {phone}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {location}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        as="a"
                        href="#"
                        variant="small"
                        color="blue-gray"
                        className="font-medium"
                      >
                        {total_policy_sold}
                      </Typography>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </Card> */}
      {/* 
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-3">
        <div className="bg-white w-full col-span-2 h-72 overflow-hidden">
          <Card className="h-full w-full overflow-y-scroll">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {TABLE_ROWS.map(({ name, job, date }, index) => {
                  const isLast = index === TABLE_ROWS.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={name}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {job}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs"
                        >
                          {date}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          as="a"
                          href="#"
                          variant="small"
                          color="blue-gray"
                          className="font-medium"
                        >
                          Edit
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
        <div className="bg-white h-64">
          <CustomPieChart />
        </div>
      </div> */}
      <div className="my-28"></div>
    </>
  );
}

export default DashboardOverview;
