import { useQuery, gql } from "@apollo/client";

export const GET_COMPREHENSIVE = gql`
  query getComprehensiveRisks {
    comprehensive_third_party_risk_class {
      id
      created_at
      comprehensive
      own_damage_excess_rate
      risk_id
      third_party_rate_theft
      risk_class {
        id
        name
      }
    }
  }
`;

export const GET_THIRDPARTY = gql`
  query getThirdParty {
    third_party_only_risk_class {
      basic_premium
      created_at
      fee_amount
      id
      loading_amount
      seat_amount
      total_amount
      updated_at
      risk_class {
        id
        name
      }
    }
  }
`;

export const GET_ALLRISKCLASS = gql`
  query getAllRiskClassItems {
    risk_class {
      id
      name
      status
    }
  }
`;

export const GET_ROLES = gql`
  query MyQuery {
    roles {
      name
      id
    }
  }
`;

export const GET_INTERMEDIARIES = gql`
  query MyQuery {
    intemediary {
      active_status
      commission_rate
      created_at
      email
      id
      intermediary_type
      name
      phone_number
    }
  }
`;

export const GET_ALL_SETUPS = gql`
  query getAllsetups {
    countries: country(order_by: { name: asc }) {
      id
      is_active
      iso_code
      name
      nationality
      created_at
    }
    discounts: discount {
      created_at
      id
      rate
      description
      status
    }
    banks: bank(order_by: { name: asc }) {
      code
      created_at
      id
      is_active
      name
    }
    body_types: body_type(order_by: { name: asc }) {
      id
      created_at
      is_active
      name
    }
    vehicle_makes: vehicle_make(
      order_by: { name: asc }
      where: { is_active: { _eq: true } }
    ) {
      id
      name
      vehicle_models(
        order_by: { name: asc }
        where: { is_active: { _eq: true } }
      ) {
        id
        name
      }
    }
  }
`;

export const GET_REGIONS = gql`
  query getAllRegions {
    regions {
      id
      name
    }
  }
`;

export const GET_ALL_DISTRICTS = gql`
  query getAllDistrictofRegion($regionId: uuid) {
    districts(where: { region_id: { _eq: $regionId } }) {
      id
      name
      category
      capital
      region {
        name
      }
    }
  }
`;

export const GET_ALL_TOWNS = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
    }
  }
`;

export const GET_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`;

export const GET_ALL_CLIENT = gql`
  query getAllIntermediaries {
    intemediary {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      clientTypeByClientType {
        id
        name
      }
      active_status
      branch_code
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
    insurer_companies {
      bank_details
      client_type
      email
      id
      momo_details
      phone
      name
      tin_number
      clientTypeByClientType {
        id
        name
      }
    }
  }
`;

export const GET_ROLE_PERMISSIONS = gql`
  query getRolePermissions($role_id: uuid) {
    role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        name
      }
      selected_actions
    }
  }
`;

export const GET_ALL_CLIENT_TYPES = gql`
  query getClientTypes {
    client_types {
      description
      id
      name
      created_at
      created_by
    }
  }
`;

export const GET_ALL_DIVISIONS = gql`
  query getAllDivisionsWOrkGroupForBroker($id: uuid!) {
    divisions(where: { intermediary_id: { _eq: $id } }) {
      description
      id
      name
    }
  }
`;

export const GET_ALL_TOWNS_OF_DISTRICT = gql`
  query getAllTownsOfDistrict($districtID: uuid) {
    towns(where: { district_id: { _eq: $districtID } }) {
      id
      name
      district {
        region {
          name
        }
        name
      }
    }
  }
`;

export const GETSUBCLASSES = gql`
  query getSubclassesofClass($class_id: uuid) {
    subclass(where: { class_id: { _eq: $class_id } }) {
      id
      name
      status
      description
      business_class {
        name
        description
      }
    }
  }
`;

export const GETBUSINESSCLASSES = gql`
  query getAllBusinessClasses {
    business_classes {
      id
      image_url
      name
      description
    }
  }
`;

export const GETALLPERILS = gql`
  query getAllPerils {
    perils {
      class_id
      description
      id
      name
      status
    }
  }
`;

export const GETALLEXCLUSIONS = gql`
  query getAllExclusions {
    exclusions {
      id
      name
      description
      status
    }
  }
`;

export const GETALLPERILSNEXCLUSIONSOFCOVERTYPE = gql`
  query getAllPerilsNExclusionsOfCoverType($id: uuid!) {
    view_coverage_details(where: { cover_type_id: { _eq: $id } }) {
      exclusion_name
      exclusions_id
      exclusion_description
      peril_description
      peril_id
      peril_name
    }
  }
`;

export const GETPREMIUMRATES = gql`
  query getAllPremiumRates {
    view_premium_rate {
      class_id
      class_name
      covertype_id
      covertype_name
      id
      premium_description
      premium_rate
      premium_rate_created_at
      subclass_id
      subclass_name
    }
  }
`;

export const GETCOVERTYPES = gql`
  query getCoverTypes {
    covertypes {
      description
      id
      name
      class_id
      status
    }
  }
`;

export const GETALLDISCOUNTS = gql`
  query getAllDiscounts {
    system_discount {
      description
      id
      name
      status
    }
  }
`;

export const GETDISCOUNTRATESOFDISCOUNT = gql`
  query getDiscountRatesOfDiscount($discount_id: uuid!) {
    view_discount_rates(where: { dicount_id: { _eq: $discount_id } }) {
      class_name
      subclass_name
      cover_type_name
      description
      rate
      id
      dicount_id
      discount_name
    }
  }
`;

export const GETALLLOADINGS = gql`
  query getAllLoadings {
    loadings {
      description
      id
      name
      status
    }
  }
`;

export const GETALLLOADINGRATES = gql`
  query getLoadingrates($loading_id: uuid!) {
    loading_rates(where: { loading_id: { _eq: $loading_id } }) {
      loading {
        id
        name
      }
      description
      rate
      status
      subclass {
        id
        name
      }
      covertype {
        id
        name
      }
      business_class {
        name
        id
      }
    }
  }
`;

export const DELETELOADINGRATE = gql`
  mutation deleteLoadingRate($id: uuid!) {
    delete_loading_rates_by_pk(id: $id) {
      id
    }
  }
`;

export const GETALLCOMMMISSIONS = gql`
  query Allcommissions {
    commisions {
      description
      created_at
      id
      name
      status
    }
  }
`;

export const GETALLCOMMMISSIONSRATE = gql`
  query getCommissionRate($commission_id: uuid!) {
    commisions_rates(where: { commision_id: { _eq: $commission_id } }) {
      class_id
      commision_id
      id
      intermediary_type
      rate
      status
      subclass {
        id
        description
        name
      }
      business_class {
        id
        name
        description
      }
      description
      created_at
    }
  }
`;

export const GETALLLEVIES = gql`
  query getAllLevies {
    levies {
      created_at
      description
      id
      name
      status
    }
  }
`;

export const GETLEVYRATES = gql`
  query getLevyRates($id: uuid!) {
    levies_rate(where: { levy_id: { _eq: $id } }) {
      amount
      business_class {
        id
        name
        description
      }
      covertype {
        id
        description
        name
      }
      description
      id
      levy_id
      rate
      status
      subclass {
        id
        description
        name
      }
    }
  }
`;

export const GETALLTAX = gql`
  query getAllTaxes {
    taxes {
      created_at
      description
      id
      name
      status
    }
  }
`;

export const GETTAXRATES = gql`
  query getTaxRates($id: uuid!, $company_id: uuid) {
    tax_rate(
      where: { tax_id: { _eq: $id }, insurance_company: { _eq: $company_id } }
    ) {
      business_class {
        id
        name
        description
      }
      covertype {
        id
        description
        name
      }
      description
      id
      amount
      tax_id
      rate
      status
      subclass {
        id
        description
        name
      }
    }
  }
`;

export const GETUSERCOMMISSIONS = gql`
  query getUserCommissions($id: uuid!) {
    user_commission_setup(where: { user_id: { _eq: $id } }) {
      commision {
        id
        name
      }
      settlement_mode
      id
      created_at
    }
  }
`;

export const GETTAXPAYABLE = gql`
  query MyQuery($id: uuid!) {
    tax_payable(where: { commission_payable_id: { _eq: $id } }) {
      amount
      id
      rate
      tax {
        id
        name
      }
    }
  }
`;

export const DASHBOARDAGGREGATES = gql`
  query dashboardAggregates {
    insurance_companies: insurer_companies_aggregate {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: { intermediary_type: { _eq: "agent" } }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: { intermediary_type: { _eq: "broker" } }
    ) {
      aggregate {
        count
      }
    }
    policies: policy_aggregate(distinct_on: created_at) {
      aggregate {
        count
      }
    }
    policy_holders: view_total_policyholders_count {
      total_policyholders
    }
    total_policy_sold: view_total_policy_sum {
      total_amount_sold
    }
    subscription_rate {
      rate
    }
  }
`;

export const CLIENT360AGGREGATES = gql`
  query client360Aggregates($company_id: uuid) {
    insurance_companies: insurer_companies_aggregate(
      where: { id: { _eq: $company_id } }
    ) {
      aggregate {
        count
      }
    }
    staff: company_staff_aggregate(
      where: { company_id: { _eq: $company_id } }
    ) {
      aggregate {
        count
      }
    }
    agent: intemediary_aggregate(
      where: {
        intermediary_type: { _eq: "agent" }
        company_id: { _eq: $company_id }
      }
    ) {
      aggregate {
        count
      }
    }
    broker: intemediary_aggregate(
      where: {
        intermediary_type: { _eq: "broker" }
        company_id: { _eq: $company_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GETTOTALPOLICYPERMONTH = gql`
  query getTotalPolicyPerMonth {
    months_policies: view_total_policy_sum_by_month {
      month
      total_amount
    }
  }
`;

export const GETALLINSURANCECOMPANIES = gql`
  query getAllInsuranceCompanies {
    insurer_companies {
      email
      id
      momo_details
      bank_details
      tin_number
      phone
      name
      clientTypeByClientType {
        name
        id
      }
      client_type
      company_person
      created_at
    }
  }
`;

export const GETALLINTERMEDIARIESAGENT = gql`
  query getAllIntermediariesAgent($company_id: uuid) {
    intemediary(
      where: {
        intermediary_type: { _eq: "agent" }
        company_id: { _eq: $company_id }
      }
    ) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status
      contact_person
      insurer_company {
        id
        name
      }
      nic_registration_number
      phone_number
      tin_number
    }
  }
`;

export const GETALLINTERMEDIARIESBROKER = gql`
  query getAllIntermediariesBroker($company_id: uuid) {
    intemediary(
      where: {
        intermediary_type: { _eq: "broker" }
        company_id: { _eq: $company_id }
      }
    ) {
      address
      bank_details
      email
      id
      intermediary_type
      momo_details
      name
      active_status
      contact_person
      nic_registration_number
      phone_number
      tin_number
      registration_number
    }
  }
`;

export const GETALLRISKCLASS = gql`
  query getAllriskClass {
    risk_class {
      name
      id
    }
  }
`;

export const GETDETAILOFRISKCLASS = gql`
  query getDetailsofRiskClass($id: uuid!) {
    third_party_only_risk_class(where: { risk_class: { id: { _eq: $id } } }) {
      basic_premium
      ecowas_perils
      fee_amount
      total_amount
    }
  }
`;

export const GETCOMPANYROLES = gql`
  query getCompanyRoles($id: uuid!) {
    company_role(where: { intermediary_id: { _eq: $id } }) {
      created_at
      description
      id
      name
    }
  }
`;

export const GETALLDIVISIONSORWORKGROUPS = gql`
query getAllDivisionsWOrkGroup($id: uuid!) {
  divisions(where: {intermediary_id: {_eq: $id}}) {
    description
    id
    name
  }
}

`;

export const GETSTAFFBRANCHESANDDIVISIONS = gql`
  query getStaffBranchesAndDivisions($staff_id: uuid!) {
    staff_branches_divisions(where: { staff_id: { _eq: $staff_id } }) {
      staff_id
      division {
        id
        name
        description
      }
      branch {
        id
        name
        code
      }
      id
    }
  }
`;

export const GETSTAFFBRANCHESANDDIVISIONSFORAGENTS = gql`
  query getStaffBranchesAndDivisionsForAgents($agent_id: uuid!) {
    staff_branches_divisions(where: { agent_id: { _eq: $agent_id } }) {
      staff_id
      division {
        id
        name
        description
      }
      branch {
        id
        name
        code
      }
      id
    }
  }
`;

export const GETALLBRANCHES = gql`
query MyQuery($id:uuid) {
  branch(where: {intermediary_id: {_eq: $id}}) {
    address
    name
    code
    id
  }
}

`;

export const GETALlUSERCOMMISSIONS = gql`
  query getUserSelectedCommissions($id: uuid!) {
    user_commission_setup(where: { user_id: { _eq: $id } }) {
      id
      settlement_mode
      commision {
        id
        name
      }
    }
  }
`;

export const GETCOMPANYSTAFF = gql`
  query MyQuery($company_id: uuid) {
    company_staff(
      where: {
        active_status: { _eq: true }
        intemediary: { id: { _eq: $company_id } }
      }
      order_by: { created_at: desc }
    ) {
      phone_number_two
      phone_number_one
      created_at
      tin_number
      staff_number
      name
      momo_details
      is_first_time
      cash_limit
      guarantor_details
      email
      id
      id_number
      id_type
      active_status
      bank_details
      preferred_payment_channel: preferred_payment_method
      division_id
      dob
      role_id
      branch {
        id
        name
      }
    }
  }
`;

export const GETCOMPANYAGENTS = gql`
query MyQuery($company_id: uuid!) {
  sub_agents(where: {intermediary_id: {_eq: $company_id}}) {
    active_status
    agent_code
    balance
    cash_limit
    agent_meta_data
    email
    first_name
    id
    is_first_time
    last_name
    password
    phone_number
    role_id
  }
  sub_agents_accounts {
    account_details
    account_name
    account_type
    active_status
    agent_id
    created_at
    id
  }
}

`;

export const GETALLFUNDSREQUEST = gql`
  query getAllFundsRequest($company_id: uuid!) {
    view_fund_account_details(
      where: { company_id: { _eq: $company_id } }
      order_by: { created_at: desc }
    ) {
      amount
      parameter_id
      branch_name
      company_role_name
      company_staff_name
      division_name
      funds_request_id
      status
      agent: sub_agent_name
      parameter_type
      agent_balance
      branch_balance
      division_balance
      role_balance
      staff_balance
    }
  }
`;

export const GETALLFUNDSREQUESTPENDING = gql`
  query getAllFundsRequest($company_id: uuid) {
    view_fund_account_details(
      where: { status: { _eq: "pending" }, company_id: { _eq: $company_id } }
      order_by: { created_at: desc }
    ) {
      amount
      parameter_id
      branch_name
      company_role_name
      staff_cash_limit
      agent_cash_limit
      company_staff_name
      division_name
      funds_request_id
      status
      agent: sub_agent_name
      parameter_type
      agent_balance
      created_at
      branch_balance
      division_balance
      role_balance
      staff_balance
    }
  }
`;

export const GETBALANCEOFUSERS = gql`
  query getBalanceofUsers($company_id: uuid) {
    positive_balance_view(where: { grouping_id: { _eq: $company_id } }) {
      name
      balance
      record_id
      record_type
    }
  }
`;

export const GETALLAGENTS = gql`
  query getAllAgentsofCompany($company_id: uuid) {
    agents: view_combined_agent_subagent(
      where: { company_id: { _eq: $company_id } }
    ) {
      first_name
      last_name
      phone_number
      role_id
      nic_registration_number
      is_first_time
      intermediary_type
      balance
      company_id
      agent_id
      agent_code
      active_status
    }
  }
`;

export const GETPERMISSIONSFORSPECIFICROLE = gql`
  query getPermissionsForSpecificRole($role_id: uuid!) {
    company_role_permissions(where: { role_id: { _eq: $role_id } }) {
      permission_setup {
        id
        name
      }
      role_id
      selected_actions
    }
  }
`;

export const INSURANCECOMPANYDASHBOARD = gql`
  query insuranceCompanyDashboard($id: uuid!) {
    written_premium: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    premium_paid: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    premium_outstanding: view_total_policy_sum_insurance_company(
      where: { insurer_company: { _eq: $id } }
    ) {
      total_amount_sold
    }
    new_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "new" } }
    ) {
      aggregate {
        count
      }
    }
    lapse_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "lapse" } }
    ) {
      aggregate {
        count
      }
    }
    renewed_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, policy_stage: { _eq: "renewed" } }
    ) {
      aggregate {
        count
      }
    }
    active_policy: view_joined_policy_count_companies_aggregate(
      where: { insurer_company: { _eq: $id }, status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    all_commissions: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    commission_paid: paid_commissions_company_aggregate(
      where: { insurance_company_id: { _eq: $id } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    outstanding_commissions: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id }, status: { _eq: "pending" } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    total_agents: intemediary_aggregate(
      where: {
        insurer_company: { id: { _eq: $id } }
        intermediary_type: { _eq: "agent" }
      }
    ) {
      aggregate {
        count
      }
    }
    total_brokers: intemediary_aggregate(
      where: {
        insurer_company: { id: { _eq: $id } }
        intermediary_type: { _eq: "broker" }
      }
    ) {
      aggregate {
        count
      }
    }
    total_staff: company_staff_aggregate(
      where: { insurer_company: { id: { _eq: $id } } }
    ) {
      aggregate {
        count
      }
    }
    total_policy_holders: policy_aggregate(
      where: {
        request: {
          insurer_company: { _eq: $id }
          is_paid: { _eq: true }
          request_stage: { _eq: "policy" }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    tax_payable_aggregate: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id } }
    ) {
      aggregate {
        sum {
          amount: total_tax_deductions
        }
      }
    }
  }
`;

export const GETPOLICYTECHNICALTRANSACTION = gql`
  query GETPOLICYTECHNICALTRANSACTION(
    $policy_id: uuid
    $policy_number: String
  ) {
    saved_quoatations: request(
      where: { policies: { id: { _eq: $policy_id } }, is_paid: { _eq: false } }
    ) {
      amended_rated_details
      amount
      benefit_added_details
      businessClassByBusinessClass {
        name
        id
      }
      cover_type
      created_at
      duration
      end_date
      id
      payment_channel
      request_stage
      sub_agent {
        id
        first_name
        email
        last_name
      }
      company_staff {
        email
        id
        phone_number_one
      }
    }
    view_joined_policy_count_companies(
      where: { policy_number: { _eq: $policy_number } }
    ) {
      certificate_number
      created_at
      created_by
      debit_number
      id
      policy_number
      policy_stage
      policy_type
      reciept_number
      status
      total_policies
    }
  }
`;

export const GETPOLICYFINANCIALTRANSACTION = gql`
  query MyQuery($policy_id: uuid) {
    taxes_paid: tax_payable(
      where: { commissions_payable: { policy_id: { _eq: $policy_id } } }
    ) {
      amount
      created_at
      id
      rate
      tax {
        id
        name
      }
      commissions_payable {
        commission_amount
        reference_amount
        status
        user_type
      }
      commission_payable_broker {
        commission_amount
        reference_amount
        status
        user_type
      }
    }
    commissions_paid: commissions_payable {
      commision {
        code
        id
        name
      }
      commission_amount
      date_created
      date_updated
      id
      intermediary
      rate
      reference_amount
      status
      total_tax_deductions
      user_type
    }
  }
`;

export const GETPOLICY360 = gql`
  query policy360forBroker($id: uuid) {
    policy(where: { request: { intermediary_option: { _eq: $id } } }) {
      policy_number
      request {
        policyHolder: customer {
          first_name
          last_name
        }
        risk_class {
          id
          name
        }
        cover_type
        businessClassByBusinessClass {
          id
          name
        }
        end_date
        start_date
        insurance_company: insurerCompanyByInsurerCompany {
          email
          name
          id
          phone
        }
        intemediary {
          id
          intermediary_type
          name
          phone_number
        }
      }
    }
  }
`;

export const POLICYPERFORMANCE = gql`
  query MyQuery($policy_id: uuid) {
    policy_aggregate(
      where: {
        request: {
          policies: { id: { _eq: $policy_id } }
          is_paid: { _eq: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    commissions_payable_aggregate(where: { policy_id: { _eq: $policy_id } }) {
      aggregate {
        sum {
          commission_amount
          total_tax_deductions
        }
      }
    }
  }
`;

export const BROKERCOMPANYDASHBOARD = gql`
  query InsuranceBrokerDashbaord($id: uuid) {
    total_policy_holders: policy_aggregate(
      where: {
        request: {
          created_by: { _eq: $id }
          is_paid: { _eq: true }
          request_stage: { _eq: "policy" }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    company_staff_aggregate(where: { intermediary_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    sub_agents_aggregate(where: { intermediary_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    insurers: request_aggregate(
      where: { is_paid: { _eq: true }, created_by: { _eq: $id } }
      distinct_on: insurer_company
    ) {
      aggregate {
        count
      }
    }
    new_policy: view_joined_policy_count_companies_aggregate(
      where: { created_by: { _eq: $id }, policy_stage: { _eq: "new" } }
    ) {
      aggregate {
        count
      }
    }
    lapse_policy: view_joined_policy_count_companies_aggregate(
      where: { created_by: { _eq: $id }, policy_stage: { _eq: "lapse" } }
    ) {
      aggregate {
        count
      }
    }
    renewed_policy: view_joined_policy_count_companies_aggregate(
      where: { created_by: { _eq: $id }, policy_stage: { _eq: "renewed" } }
    ) {
      aggregate {
        count
      }
    }
    active_policy: view_joined_policy_count_companies_aggregate(
      where: { created_by: { _eq: $id }, status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    premium_paid: view_total_policy_sum_insurance_company(
      where: { created_by: { _eq: $id } }
    ) {
      total_amount_sold
    }
    paid_commissions: commissions_payable_aggregate(
      where: { intermediary: { _eq: $id }, status: { _eq: "paid" } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    pending_commissions: commissions_payable_aggregate(
      where: { intermediary: { _eq: $id }, status: { _eq: "pending" } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    view_broker_total_premiums_of_business_class(
      where: { broker_id: { _eq: $id } }
    ) {
      name
      total_premium
    }
    bar_chart_data_commissions: view_commission_summary_by_business_class(
      where: { intermediary: { _eq: $id } }
    ) {
      business_class_name
      name
      total_commission
    }
    month_bar_chart_data: view_commission_summary_by_business_class_months_display(
      where: { intermediary: { _eq: $id } }
    ) {
      month_category
      total_commission
      business_class_name
      commission_name
    }
  }
`;

export const GETALLPAYABLECOMMISSIONS = gql`
  query getAllPayabaleCommissions($id: uuid!, $status_type: String) {
    view_payable_commissions_company(
      where: { insurance_company: { _eq: $id }, status: { _eq: $status_type } }
      order_by: { created_at: desc }
    ) {
      payable_commission_id
      account_details
      agent_first_name
      agent_last_name
      staff_name
      user_type
      created_at
      commission_name
      sub_class_id
      risk_or_sub_class_name
      reference_amount
      gross_amount
      rate
      net_amount
      total_tax_deductions
      status
      business_class_name
    }
  }
`;

export const GETREPORTDATA = gql`
  query MyQuery($startDate: timestamp, $endDate: timestamp) {
    policy(
      where: { created_at: { _gte: $startDate, _lte: $endDate } }
      order_by: { created_at: desc }
    ) {
      certificate_number
      created_at
      debit_number
      documents: documentsByPolicyId {
        document_type
        id
        name
      }
      policy_number
      id
      policy_stage
      reciept_number
      status
      quotation: request {
        amended_rated_details
        premium: amount
        benefit_added_details
        business_class: businessClassByBusinessClass {
          name
          id
          description
        }
        cover_type
        created_at
        currency: currencyByCurrency {
          name
          id
        }
        customer {
          created_at
          customer_type
          email
          first_name
          id
          last_name
          phone_number
        }
        duration
        end_date
        id
        is_paid
        payment_channel
        quotation_number: request_id
        request_stage
        start_date
        vehicle_details
        risk_class {
          id
          name
        }
        user_type
        sub_agent {
          id
          first_name
          agent_code
          email
          last_name
        }
        insurance_company: insurerCompanyByInsurerCompany {
          address
          bank_details
          client_type
          email
          id
          name
          phone
        }
        intermediary_added: intemediary {
          email
          id
          id_number
          intermediary_type
          intermediary_type_code
          name
          nic_registration_number
          phone_number
          sub_agents {
            agent_code
          }
        }
      }
    }
  }
`;

export const COMMISSIONAGGREGATE = gql`
  query MyQuery($id: uuid!) {
    Motor_policies: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    Home_owners: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Home Owners" }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    Motor_comprehensive: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Comprehensive" }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    Motor_third_party: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Third Party" }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    Motor_third_party_fire: view_converted_request_aggregate(
      where: {
        insurer_company: { _eq: $id }
        business_class_name: { _eq: "Motor Insurance" }
        cover_type: { _eq: "Third Party Fire and Theft" }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    all_commissions: commissions_payable_aggregate(
      where: { insurance_company: { _eq: $id } }
    ) {
      aggregate {
        sum {
          commission_amount
        }
      }
    }
    all_types_of_commissios: view_type_of_commissions_generated_company(
      where: { insurance_company: { _eq: $id } }
    ) {
      commission_id
      commission_name
      total_amount
    }
  }
`;

export const GETPAYMENTREQUISITIONDetail = gql`
  query getAllPaymentRequisition($id: uuid!) {
    view_payment_requistion_details(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
    ) {
      account_details
      created_at
      id
      insurance_company_id
      payee_details
      provider_transaction_id
      payee_id
      payee_name
      payee_number
      payee_type
      payment_stage
      policy_id
      rate
      reference
      reference_amount
      transaction_type: transaction_name
    }
    payment_requisition(
      where: {
        payment_stage: { _eq: "payment_order" }
        status: { _eq: "pending" }
        insurance_company_id: { _eq: $id }
      }
    ) {
      amount_requisitioned
    }
  }
`;

export const GETPOLICYDETAILTEN = gql`
  query getPolicyDetailsTen {
    policy(where: {}, order_by: { created_at: desc }, limit: 10) {
      id
      request {
        id
        start_date
        end_date
        customer {
          first_name
          last_name
        }
        business_class: businessClassByBusinessClass {
          name
          id
        }
        sub_class: risk_class {
          id
          name
        }
      }
      created_at
    }
  }
`;
export const GETPOLICYDETAIL = gql`
  query getPolicyDetails($policy_number: String) {
    policy(where: { policy_number: { _ilike: $policy_number } }) {
      id
      request {
        id
        start_date
        end_date
        customer {
          first_name
          last_name
        }
        business_class: businessClassByBusinessClass {
          name
          id
        }
        sub_class: risk_class {
          id
          name
        }
      }
    }
  }
`;

export const GETPAYMENTOFPOLICY = gql`
  query getPaymentsOfpolicy($policy_id: uuid!) {
    payment_history: approved_payment_requisitions(
      where: { policy_id: { _eq: $policy_id } }
    ) {
      amount
      approved_by
      created_at
      id
      status
      transaction_number
      transaction_type
      description
      payment_requisition {
        payee_type
        payee_id
        reference
        reference_amount
        rate
        id
        created_by
      }
    }
  }
`;

export const GETALLBANKS = gql`
  query getALlBanks {
    bank {
      code
      id
      name
    }
  }
`;

export const DRIVERAGGREGATE = gql`
  query MyQuery {
    active_drivers: drivers_aggregate(
      where: { account_status: { _eq: "active" } }
    ) {
      aggregate {
        count
      }
    }
    inactive_drivers: drivers_aggregate(
      where: { account_status: { _eq: "inactive" } }
    ) {
      aggregate {
        count
      }
    }
    total_drivers: drivers_aggregate {
      aggregate {
        count
      }
    }
    total_collections: collection_request_aggregate(
      where: { status: { _eq: "success" } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    total_withdrawals: driver_withdrawal_request_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
    total_drivers_policies: request_aggregate(
      where: { payment_channel: { _eq: "invest-2-insure" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GETALLSAVEDQUOTATIONS = gql`
  query MyQuery($id: uuid) {
    request(
      where: {
        insurer_company: { _eq: $id }
        request_stage: { _eq: "quote" }
        is_paid: { _eq: false }
      }
    ) {
      amended_rated_details
      amount
      benefit_added_details
      businessClassByBusinessClass {
        name
        id
      }
      cover_type
      created_at
      currency
      company_staff {
        id
        name
        phone_number_one
      }
      sub_agent {
        id
        first_name
        phone_number
        last_name
      }
      duration
      end_date
      id
      is_paid
      payment_channel
      request_stage
      registration_number_text
      start_date
      user_type
      vehicle_details
      risk_class {
        id
        name
      }
    }
  }
`;

export const GETALLNEWPOLICIES = gql`
  query newPolicies($id: uuid) {
    policy(
      where: {
        request: { insurer_company: { _eq: $id } }
        policy_stage: { _eq: "new" }
      }
    ) {
      certificate_number
      created_at
      debit_number
      id
      policy_number
      policy_stage
      reciept_number
      status
      request {
        amended_rated_details
        amount
        benefit_added_details
        businessClassByBusinessClass {
          name
          id
        }
        cover_type
        created_at
        currency
        company_staff {
          id
          name
          phone_number_one
        }
        duration
        end_date
        id
        is_paid
        payment_channel
        request_stage
        registration_number_text
        start_date
        user_type
        vehicle_details
        risk_class {
          id
          name
        }
        sub_agent {
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
`;

export const GETALLRENEWALPOLICIES = gql`
  query renewedPolicies($id: uuid) {
    policy(
      where: {
        request: { insurer_company: { _eq: $id } }
        policy_stage: { _eq: "renewed" }
      }
    ) {
      certificate_number
      created_at
      debit_number
      id
      policy_number
      policy_stage
      reciept_number
      status
      request {
        amended_rated_details
        amount
        benefit_added_details
        businessClassByBusinessClass {
          name
          id
        }
        cover_type
        created_at
        currency
        company_staff {
          id
          name
          phone_number_one
        }
        duration
        end_date
        id
        is_paid
        payment_channel
        request_stage
        registration_number_text
        start_date
        user_type
        vehicle_details
        risk_class {
          id
          name
        }
        sub_agent {
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
`;

export const GETALLLAPSEDPOLICIES = gql`
  query lapsedPolicies($id: uuid, $todayDate: date) {
    policy(
      where: {
        request: {
          insurer_company: { _eq: $id }
          end_date: { _lte: $todayDate }
        }
      }
    ) {
      certificate_number
      created_at
      debit_number
      id
      policy_number
      policy_stage
      reciept_number
      status
      request {
        amended_rated_details
        amount
        benefit_added_details
        businessClassByBusinessClass {
          name
          id
        }
        cover_type
        created_at
        currency
        company_staff {
          id
          name
          phone_number_one
        }
        duration
        end_date
        id
        is_paid
        payment_channel
        request_stage
        registration_number_text
        start_date
        user_type
        vehicle_details
        risk_class {
          id
          name
        }
        sub_agent {
          first_name
          id
          last_name
          phone_number
        }
      }
    }
  }
`;

export const GETALLPAYABLETAXES = gql`
  query MyQuery($company_id: uuid) {
    tax_payable(
      order_by: { created_at: asc }
      where: {
        commissions_payable: { insurance_company: { _eq: $company_id } }
      }
    ) {
      amount
      rate
      id
      created_at
      commissions_payable {
        policy {
          policy_number
          request {
            cover_type
            amount
            businessClassByBusinessClass {
              name
              id
            }
            risk_class {
              id
              name
            }
            sub_agent {
              id
              last_name
              first_name
            }
            company_staff {
              id
              name
            }
          }
        }
        total_tax_deductions
        commission_amount
      }
      tax {
        id
        name
      }
    }
  }
`;

export const GETPAYMENTAPPROVALS = gql`
  query MyQuery($id: uuid) {
    approved_payment_requisitions(
      where: { insurance_company_id: { _eq: $id } }
    ) {
      amount
      created_at
      description
      id
      status
      transaction_number
      transaction_type
      payment_requisition {
        amount_requisitioned
        created_at
        initiation_type
        payee_id
        payee_type
        rate
        reference_amount
      }
      policy {
        policy_number
        status
        debit_number
        certificate_number
        request {
          amount
          amended_rated_details
          benefit_added_details
          company_staff {
            id
            name
            phone_number_one
          }
          cover_type
          businessClassByBusinessClass {
            id
            name
          }
          risk_class {
            id
            name
          }
          sub_agent {
            first_name
            id
            last_name
            phone_number
          }
        }
      }
    }
  }
`;

export const Customers = gql`
  query MyQuery($company_id: uuid) {
    customers(where: { owner: { _eq: $company_id } }) {
      customer_type
      email
      first_name
      ghana_card
      id
      last_name
      phone_number
      created_at
    }
  }
`;

export const GETSUBSCRIPTION = gql`
  query MyQuery($company_id: uuid) {
    system_client_payment(
      where: { insurance_company_id: { _eq: $company_id } }
    ) {
      amount
      datecreated
      paid_by
      reference_number
      status
      subscription_amount
      policy {
        policy_number
        request {
          amount
          businessClassByBusinessClass {
            id
            name
          }
          cover_type
        }
      }
    }
  }
`;

export const GETTAXREPORT = gql`
  query MyQuery($company_id: uuid) {
    tax_payable(
      where: {
        commissions_payable: { insurance_company: { _eq: $company_id } }
      }
    ) {
      amount
      created_at
      rate
      id
      tax {
        id
        name
      }
      commissions_payable {
        commission_amount
        rate
        reference_amount
        status
        commision {
          id
          name
        }
      }
    }
  }
`;

export const COMMISSIONREPORT = gql`
  query MyQuery($company_id: uuid) {
    view_payment_requistion_details(
      where: {
        transaction_category: { _eq: "commission" }
        insurance_company_id: { _eq: $company_id }
      }
    ) {
      account_details
      created_by
      payee_details
      payee_name
      payee_number
      payee_type
      payment_stage
      provider_transaction_id
      rate
      reference
      reference_amount
      status
      transaction_name
      transaction_category
      transaction_type_id
      policy_id
    }
  }
`;

export const POLICYREPORT = gql`
  query geyPolicyDetails($id: uuid) {
    policy(where: { id: { _eq: $id } }) {
      certificate_number
      debit_number
      policy_number
      request {
        amount
        payment_channel
        sub_agent {
          email
          first_name
          id
          last_name
        }
        businessClassByBusinessClass {
          id
          name
        }
        company_staff {
          id
          name
        }
        cover_type
      }
    }
  }
`;
