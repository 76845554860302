import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import {
  CustomDeleteModal,
  CustomModal,
  DetailDrawer,
  HeaderIcon,
  RenderAdminDetail,
} from "../../../components";
import useSWR from "swr";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { AdminType, UpdateType } from "../../../types/admin";
import { ToastContainer, toast } from "react-toastify";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddAdminModal } from "../../../components/forms/add-admin";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { useHistory } from "react-router-dom";
import { BiBlock } from "react-icons/bi";
import { useQuery } from "@apollo/client";
import { GETPAYMENTREQUISITIONDetail } from "../../../GraphQl/queries";

type RequisitionType = {
  account_details: {
    acct_number: string;
    bank_name: string;
    branch: string;
  };
  created_at: string;
  id: string;
  insurance_company_id: string;
  payee_details: string;
  payee_id: string;
  payee_name: string;
  payee_number: string;
  payee_type: string;
  payment_stage: string;
  provider_transaction_id: string;
  policy_id: string;
  rate: string;
  transaction_type: string;
  reference: string;
  reference_amount: string;
  // amount_requisitioned: string;
  transaction_name: string;
};

type PaymentHistoryType = {
  amount: string;
  approved_by: string;
  created_at: string;
  id: string;
  status: string;
  transaction_number: string;
  transaction_type: string;
  description: string;
  payment_requisition: {
    payee_type: string;
    payee_id: string;
    reference: string;
    reference_amount: string;
    rate: string;
    id: string;
    created_by: string;
  };
};

const PaymentOrder = () => {
  const history = useHistory();
  const [user, setUser] = useState<UserType>();
  const [requistionText, setRequistionSearch] = useState("");
  const [activeRequistionPage, setActiveRequistionPage] = useState(1);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<UpdateType>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [activeHistory, setActiveHistory] = useState(1);
  const [activeRequisition, setActiveRequisition] = useState(1);
  const [approving, setApproving] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  const {
    register: updateRegister,
    handleSubmit: updateSubmit,
    setValue,
    formState: { errors: updateError, isSubmitting: isUpdateSubmitting },
  } = useForm<UpdateType>({
    defaultValues: defaultValues,
  });

  const {
    data: requisition,
    loading: loadingRequistion,
    refetch: refetchRequisition,
  } = useQuery<{
    view_payment_requistion_details: RequisitionType[];
    payment_requisition: any;
  }>(GETPAYMENTREQUISITIONDetail, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const PAGE_SIZE = 6;

  const handleOpen = () => {};

  const handleRowClick = (item: UpdateType) => {
    setSelectedRow(item);
    setOpenDrawer(true);
  };

  const handleRequistionSerach = (e: any) => {
    setRequistionSearch(e.target.value);
    setActiveRequistionPage(1);
  };

  const filterData = (admin: AdminType) => {
    const isTextMatch =
      admin?.name?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      admin?.role?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      admin?.email?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      admin?.phoneNumber
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase());
    return isTextMatch;
  };

  const filterRequisition = (data: RequisitionType) => {
    const isTextMatch =
      data?.payee_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_type
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.reference?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      data?.payee_number
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.payment_stage
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.rate?.toLowerCase()?.includes(requistionText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedRequisitionData = requisition?.view_payment_requistion_details
    ?.filter(filterRequisition)
    .slice((activeHistory - 1) * PAGE_SIZE, activeHistory * PAGE_SIZE);

  const nextRequisitionPage = () => {
    if (
      (activeRequisition - 1) * PAGE_SIZE <
      (requisition?.view_payment_requistion_details?.filter(filterRequisition)
        .length || 0)
    ) {
      setActiveRequisition(activeHistory + 1);
    }
  };

  const prevRequisitionPage = () => {
    if (activeHistory > 1) {
      setActiveRequisition(activeRequisition - 1);
    }
  };

  // Filtered and paginated data for the current page
  // const paginatedData = data?.admins
  //   ?.filter(filterData)
  //   .slice(
  //     (activeRequistionPage - 1) * PAGE_SIZE,
  //     activeRequistionPage * PAGE_SIZE
  //   );

  // const nextPage = () => {
  //   if (
  //     (activeRequistionPage - 1) * PAGE_SIZE <
  //     (data?.admins?.filter(filterData).length || 0)
  //   ) {
  //     setActiveRequistionPage(activeRequistionPage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (activeRequistionPage > 1) {
  //     setActiveRequistionPage(activeRequistionPage - 1);
  //   }
  // };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value);
      });
    }
  }, [defaultValues]);

  const handleDelete = async () => {
    setOpenDrawer(false);
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/auth/delete-admin`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          admin_id: selectedRow?.admin_id,
        }),
      });

      const req = await response.json();

      if (response.ok) {
        toast.success("Admin deleted successfully");
        SetShowDeleteModal(false);
        if (user?.email === selectedRow?.email) {
          localStorage.clear();
          history?.push("/");
          window.location.reload();
        }
      } else {
        toast.error(req?.msg);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error deleting admin");
      setLoading(false);
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedRequisitionData) {
      const allRows = paginatedRequisitionData.map((item) => item.id);
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  React.useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      />

      <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        children={<>{/* <RenderAdminDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Payment Order" />

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of All Outstanding Payments Orders
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                {selectedRows?.length > 0 && (
                  <Button
                    onClick={() => {}}
                    size="sm"
                    color="teal"
                    className="text-xs font-normal capitalize bg-brand-cyan w-64"
                  >
                    {approving ? "loading..." : "Approve payment"}
                  </Button>
                )}
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={requistionText}
                    onChange={handleRequistionSerach}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className="w-full bg-white table-auto">
            <thead className="">
              <tr>
                <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedRows.length === paginatedRequisitionData?.length
                    }
                  />
                </th>
                {[
                  "Payment Transaction Type",
                  "Transaction Reference",
                  "Provider Transaction Id",
                  "Payee Details",
                  "Payment Details",
                  "Account Details",
                  "Amount",
                  "",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedRequisitionData?.map((item, index: number) => {
                const isLast = index === paginatedRequisitionData?.length - 1;
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handleRowCheckboxChange(event, item.id)
                        }
                        checked={selectedRows.includes(item.id)}
                      />
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.transaction_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.reference}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.provider_transaction_id}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Client Name:{" "}
                        <span className="font-normal">{item?.payee_name}</span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Client Type:{" "}
                        <span className="font-normal">{item?.payee_type}</span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Phone No.:{" "}
                        <span className="font-normal">
                          {item?.payee_number}
                        </span>
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        transaction_type:{" "}
                        <span className="font-normal">
                          {item?.transaction_type}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Reference Amount:{" "}
                        <span className="font-normal">
                          {item?.reference_amount}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Rate:{" "}
                        <span className="font-normal">{item?.rate} %</span>
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Bank:{" "}
                        <span className="font-normal">
                          {item?.account_details?.bank_name}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Branch:{" "}
                        <span className="font-normal">
                          {item?.account_details?.branch}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        A/C No:{" "}
                        <span className="font-normal">
                          {item?.account_details?.acct_number}
                        </span>
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {"GH₵ " +
                          (
                            (requisition?.payment_requisition[index]
                              .amount_requisitioned as any) ?? 0
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Button
                        // onClick={() => { setChosenId(item?.payable_commission_id); setShowConfirmation(true); }}
                        size="sm"
                        // disabled={loadingRow === item?.payable_commission_id}
                        color="teal"
                        className="text-xs font-normal capitalize bg-brand-cyan"
                      >
                        Approve Order
                        {/* {loadingRow === item.payable_commission_id ? "Loading" : "Request to pay"} */}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {/* {isLoading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )} */}

        {/* {!paginatedData && !isLoading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )} */}

        {/* Paginator */}
        {/* {paginatedData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prevPage}
                  disabled={activeRequistionPage === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                  Page{" "}
                  <strong className="text-gray-900">
                    {activeRequistionPage}
                  </strong>{" "}
                  of{" "}
                  <strong className="text-gray-900">
                    {Math.ceil(
                      (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={nextPage}
                  disabled={
                    activeRequistionPage ===
                    Math.ceil(
                      (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )} */}
      </div>
    </>
  );
};

export default PaymentOrder;
