import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { NavLink, Link, useHistory } from "react-router-dom";
// import Logo from "../../assets/logo.png";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  MdApproval,
  MdAutorenew,
  MdDashboard,
  MdOutlineDiscount,
  MdOutlinePolicy,
  MdPayments,
  MdReport,
  MdSettings,
  MdSpaceDashboard,
  MdWorkspacePremium,
} from "react-icons/md";
import { MdOutlinePhonelinkSetup } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import {
  FaClipboardUser,
  FaCoins,
  FaMapLocation,
  FaMoneyBillWave,
  FaUserShield,
  FaUserTie,
  FaUsers,
} from "react-icons/fa6";
import { TiPointOfInterestOutline } from "react-icons/ti";
import { GiCoins } from "react-icons/gi";
import {
  BiBuilding,
  BiChevronDown,
  BiChevronRight,
  BiMoneyWithdraw,
} from "react-icons/bi";
import { IoBriefcase, IoSettings } from "react-icons/io5";
import { FaTruckLoading } from "react-icons/fa";
import { AiOutlineTransaction } from "react-icons/ai";
import { RiChatNewFill, RiFundsLine } from "react-icons/ri";
import { TbDeviceMobileCog } from "react-icons/tb";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { GET_ROLE_PERMISSIONS } from "../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import { UserType } from "../../types/user";
import { DEFAULTPRIMARYCOLOR } from "../../util";

export function SidebarContent() {
  const userData = localStorage.getItem("user");
  const [logo, setLogo] = useState<string>("");
  const [colors, setColors] = useState<string[]>();

  const { data, loading } = useQuery(GET_ROLE_PERMISSIONS, {
    variables: {
      role_id: localStorage.getItem("role_id"),
    },
  });

  const history = useHistory();
  const [open, setOpen] = useState<string>(
    localStorage.getItem("sidebarOpen") ?? "0"
  );

  const handleOpen = (value: string) => {
    setOpen(open === value ? "0" : value);
    localStorage.setItem("sidebarOpen", open === value ? "0" : value);
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      history?.push("/");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to log out");
    }
  };

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);
      if (data?.logo_url?.length! > 5) {
        setLogo(data?.logo_url!);
      }
      setColors(data.colors);
    }
  }, []);

  return (
    <Card className="sidebar-container h-full overflow-y-scroll bg-black w-full max-w-[20rem] rounded-none  p-4">
      <div className="mb-1 p-4 flex items-center justify-center">
        <img src={logo} alt="logo" className="w-full h-20 object-contain" />
      </div>
      <List>
        {localStorage.getItem("usertype") !== "staff" && (
          <>
            <a href={"/dashboard"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard"
                      ? `${
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`
                        }`
                      : "",
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${colors![0]?.toLowerCase()}-400`
                    : "bg-red-400"
                } hover:text-white text-white capitalize`}
              >
                <ListItemPrefix>
                  <MdDashboard />
                </ListItemPrefix>
                Overview
              </ListItem>
            </a>

            <a href={"/dashboard/client"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/client"
                      ? `${
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`
                        }`
                      : "",
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${colors![0]?.toLowerCase()}-400`
                    : "bg-red-400"
                } hover:text-white text-white capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <FaUserTie />
                </ListItemPrefix>
                Client 360
              </ListItem>
            </a>

            <a href={"/dashboard/policy"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/policy"
                      ? `${
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`
                        }`
                      : "",
                }}
                className={` text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${colors![0]?.toLowerCase()}-400`
                    : "bg-red-400"
                } hover:text-white text-white capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <FaUserTie />
                </ListItemPrefix>
                Policy 360
              </ListItem>
            </a>

            {/* <a href={"/dashboard/policy"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname === "/dashboard/policy"
                      ? `${
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`
                        }`
                      : "",
                }}
                className={`${
                  history?.location?.pathname === "/dashboard/policy" &&
                  "bg-red-400"
                } text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${colors![0]?.toLowerCase()}-400`
                    : "bg-red-400"
                } hover:text-white text-white capitalize -mt-1`}
              >
                <ListItemPrefix>
                  <MdOutlinePolicy />
                </ListItemPrefix>
                Policy 360
              </ListItem>
            </a> */}

            {/* <a href={"/dashboard/funding-approval"}>
              <ListItem
                style={{
                  background:
                    history?.location?.pathname ===
                    "/dashboard/funding-approval"
                      ? `${
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`
                        }`
                      : "",
                }}
                className={` p-0 text-sm hover:${
                  colors?.length! > 1
                    ? `bg-${colors![0]?.toLowerCase()}-400`
                    : "bg-red-400"
                } hover:text-white py-2.5 ml-2 text-white capitalize`}
              >
                <ListItemPrefix>
                  <MdApproval strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                funding approval
              </ListItem>
            </a> */}
          </>
        )}
        <List className="text-white bg-black -mt-0.5">
          <Accordion
            open={open === "1" || open === "2" || open === "2.2"}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "1" || open === "2" || open === "2.2"
                    ? "rotate-180"
                    : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              style={{
                background:
                  history?.location?.pathname === "/dashboard/setup"
                    ? `${
                        colors?.length! > 1
                          ? `${colors![0]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`
                    : "",
              }}
              className={`p-0 text-sm hover:${
                colors?.length! > 1
                  ? `bg-${colors![0]?.toLowerCase()}-400`
                  : "bg-red-400"
              } hover:text-white text-white capitalize`}
              selected={open === "1" || open === "2" || open === "2.2"}
            >
              <AccordionHeader
                onClick={() => handleOpen("1")}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <MdOutlinePhonelinkSetup color="white" />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
                  System Setup
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 text-white capitalize">
              <List className="p-0 z-50 bg-black">
                <a href={"/dashboard/setup/branches"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname ===
                        "/dashboard/setup/branches"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <BiBuilding />
                    </ListItemPrefix>
                    Branch
                  </ListItem>
                </a>
                <a href={"/dashboard/setup/workgroups"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname ===
                        "/dashboard/setup/workgroups"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <FaClipboardUser />
                    </ListItemPrefix>
                    Workgroup/Divisions
                  </ListItem>
                </a>
                <a href={"/dashboard/setup/roles"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/setup/roles"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <MdSettings />
                    </ListItemPrefix>
                    Roles & Permissions
                  </ListItem>
                </a>
                <List className="text-white bg-black -mt-1">
                  <Accordion
                    open={open === "2"}
                    icon={
                      <BiChevronDown
                        strokeWidth={2.5}
                        className={`mx-auto h-4 w-4 transition-transform ${
                          open === "2" ? "rotate-180" : ""
                        }`}
                        color="white"
                      />
                    }
                  >
                    <ListItem
                      style={{
                        background:
                          history?.location?.pathname === "/dashboard/setup"
                            ? `${
                                colors?.length! > 1
                                  ? `${colors![0]?.toLowerCase()}`
                                  : `${DEFAULTPRIMARYCOLOR}`
                              }`
                            : "",
                      }}
                      className={`p-0 text-sm hover:${
                        colors?.length! > 1
                          ? `bg-${colors![0]?.toLowerCase()}-400`
                          : "bg-red-400"
                      } hover:text-white text-white capitalize`}
                      selected={open === "2"}
                    >
                      <AccordionHeader
                        onClick={() => {
                          handleOpen(open === "1" ? "2" : "1");
                        }}
                        className="border-b-0 p-1 py-3 text-sm text-white capitalize"
                      >
                        <ListItemPrefix>
                          <TbDeviceMobileCog color="white" />
                        </ListItemPrefix>
                        <Typography
                          color="white"
                          className="text-sm mr-auto font-normal"
                        >
                          App Users
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1 text-white capitalize">
                      <List className="p-0 z-50 bg-black">
                        <a href={"/dashboard/setup/staff"}>
                          <ListItem
                            style={{
                              background:
                                history?.location?.pathname ===
                                ("/dashboard/setup/staff" ||
                                  "/dashboard/setup/manage-staff")
                                  ? `${
                                      colors?.length! > 1
                                        ? `${colors![0]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                    }`
                                  : "",
                            }}
                            className={` p-0 text-sm hover:${
                              colors?.length! > 1
                                ? `bg-${colors![0]?.toLowerCase()}-400`
                                : "bg-red-400"
                            } hover:text-white py-2.5 ml-3 text-white capitalize`}
                          >
                            <ListItemPrefix>
                              <FaUserShield
                                strokeWidth={3}
                                className="h-3 w-5"
                              />
                            </ListItemPrefix>
                            Staff
                          </ListItem>
                        </a>

                        <a href={"/dashboard/setup/agent"}>
                          <ListItem
                            style={{
                              background:
                                history?.location?.pathname ===
                                "/dashboard/setup/agent"
                                  ? `${
                                      colors?.length! > 1
                                        ? `${colors![0]?.toLowerCase()}`
                                        : `${DEFAULTPRIMARYCOLOR}`
                                    }`
                                  : "",
                            }}
                            className={`p-0 text-sm hover:${
                              colors?.length! > 1
                                ? `bg-${colors![0]?.toLowerCase()}-400`
                                : "bg-red-400"
                            } hover:text-white py-2.5 ml-3 text-white capitalize`}
                          >
                            <ListItemPrefix>
                              <HiOutlineBuildingOffice2
                                strokeWidth={3}
                                className="h-3 w-5"
                              />
                            </ListItemPrefix>
                            Agents
                          </ListItem>
                        </a>
                      </List>
                    </AccordionBody>
                  </Accordion>
                </List>

                <a href={"/dashboard/setup/commissions"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname ===
                        "/dashboard/setup/commissions"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`p-0 text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white py-2.5 ml-2 text-white capitalize -mt-2`}
                  >
                    <ListItemPrefix>
                      <TiPointOfInterestOutline
                        strokeWidth={3}
                        className="h-3 w-5"
                      />
                    </ListItemPrefix>
                    Commissions Setup
                  </ListItem>
                </a>
                {/* <a href={"/dashboard/setup/tax"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/setup/tax"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`p-0 text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white py-2.5 ml-2 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <TiPointOfInterestOutline
                        strokeWidth={3}
                        className="h-3 w-5"
                      />
                    </ListItemPrefix>
                    Tax Setup
                  </ListItem>
                </a> */}
                {/* <a href={"/dashboard/setup/funding"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname ===
                        "/dashboard/setup/funding"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`p-0 text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white py-2.5 ml-2 text-white capitalize`}
                  >
                    <ListItemPrefix>
                      <RiFundsLine strokeWidth={3} className="h-3 w-5" />
                    </ListItemPrefix>
                    Account funding
                  </ListItem>
                </a> */}
              </List>
            </AccordionBody>
          </Accordion>
        </List>

        <List className="text-white bg-black -mt-5">
          <Accordion
            open={open === "6"}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "6" ? "rotate-180" : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              style={{
                background:
                  history?.location?.pathname === "/dashboard/setup"
                    ? `${
                        colors?.length! > 1
                          ? `${colors![0]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`
                    : "",
              }}
              className={`p-0 text-sm hover:${
                colors?.length! > 1
                  ? `bg-${colors![0]?.toLowerCase()}-400`
                  : "bg-red-400"
              } hover:text-white text-white capitalize`}
              selected={open === "6"}
            >
              <AccordionHeader
                onClick={() => handleOpen("6")}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <AiOutlineTransaction color="white" />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
                  Technical Transactions
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 text-white capitalize">
              <List className="p-0 z-50 bg-black">
                <a href={"/dashboard/quotations"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/quotations"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaUsers />
                    </ListItemPrefix>
                    Saved Quotations
                  </ListItem>
                </a>

                <a href={"/dashboard/new-policy"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/new-policy"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <RiChatNewFill />
                    </ListItemPrefix>
                    New Policies
                  </ListItem>
                </a>

                <a href={"/dashboard/renewals"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/renewals"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <MdAutorenew />
                    </ListItemPrefix>
                    Renewed Policies{" "}
                  </ListItem>
                </a>

                <a href={"/dashboard/lapsed"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/lapsed"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <MdAutorenew />
                    </ListItemPrefix>
                    Lapsed Policies
                  </ListItem>
                </a>
              </List>
            </AccordionBody>
          </Accordion>
        </List>

        <List className="text-white bg-black -mt-4">
          <Accordion
            open={open === "3"}
            icon={
              <BiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === "3" ? "rotate-180" : ""
                }`}
                color="white"
              />
            }
          >
            <ListItem
              style={{
                background:
                  history?.location?.pathname === "/dashboard/setup"
                    ? `${
                        colors?.length! > 1
                          ? `${colors![0]?.toLowerCase()}`
                          : `${DEFAULTPRIMARYCOLOR}`
                      }`
                    : "",
              }}
              className={` p-0 text-sm hover:${
                colors?.length! > 1
                  ? `bg-${colors![0]?.toLowerCase()}-400`
                  : "bg-red-400"
              } hover:text-white text-white capitalize`}
              selected={open === "3"}
            >
              <AccordionHeader
                onClick={() => handleOpen("3")}
                className="border-b-0 p-1 py-3 text-sm text-white capitalize"
              >
                <ListItemPrefix>
                  <FaMoneyBillWave color="white" />
                </ListItemPrefix>
                <Typography
                  color="white"
                  className="text-sm mr-auto font-normal"
                >
                  Financial Transaction
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 text-white capitalize">
              <List className="p-0 z-50 bg-black">
                <a href={"/dashboard/paid_commissions"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname ===
                        "/dashboard/paid_commissions"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    paid commissions
                  </ListItem>
                </a>

                <a href={"/dashboard/commissions"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/commissions"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={`text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Outstanding Commissions
                  </ListItem>
                </a>

                <a href={"/dashboard/taxes"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/taxes"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaMoneyBillWave color="white" />
                    </ListItemPrefix>
                    taxes
                  </ListItem>
                </a>
                {/* <a href={"/dashboard/requisition"}>
                  <ListItem
                    className={`${
                      history?.location?.pathname ===
                        "/dashboard/requisition" && "bg-red-400"
                    } textsm hover:${colors?.length! > 1 ? `bg-[${colors![0]?.toLowerCase()}-400` : "bg-red-400"} hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Payment requisition
                  </ListItem>
                </a> */}
                {/* <a href={"/dashboard/orders"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/orders"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Payment orders
                  </ListItem>
                </a>
                <a href={"/dashboard/approvals"}>
                  <ListItem
                    style={{
                      background:
                        history?.location?.pathname === "/dashboard/approvals"
                          ? `${
                              colors?.length! > 1
                                ? `${colors![0]?.toLowerCase()}`
                                : `${DEFAULTPRIMARYCOLOR}`
                            }`
                          : "",
                    }}
                    className={` text-sm hover:${
                      colors?.length! > 1
                        ? `bg-${colors![0]?.toLowerCase()}-400`
                        : "bg-red-400"
                    } hover:text-white text-white capitalize -mt-1`}
                  >
                    <ListItemPrefix>
                      <FaCoins />
                    </ListItemPrefix>
                    Payment approvals
                  </ListItem>
                </a> */}
              </List>
            </AccordionBody>
          </Accordion>
        </List>

        <a href={"/dashboard/reports"}>
          <ListItem
            style={{
              background:
                history?.location?.pathname === "/dashboard/reports"
                  ? `${
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                    }`
                  : "",
            }}
            className={` text-sm hover:${
              colors?.length! > 1
                ? `bg-${colors![0]?.toLowerCase()}-400`
                : "bg-red-400"
            } hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <MdReport color="white" />
            </ListItemPrefix>
            report
          </ListItem>
        </a>

        <a href={"/dashboard/settings"}>
          <ListItem
            style={{
              background:
                history?.location?.pathname === "/dashboard/settings"
                  ? `${
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`
                    }`
                  : "",
            }}
            className={`text-sm hover:${
              colors?.length! > 1
                ? `bg-${colors![0]?.toLowerCase()}-400`
                : "bg-red-400"
            } hover:text-white text-white capitalize -mt-1`}
          >
            <ListItemPrefix>
              <IoSettings color="white" />
            </ListItemPrefix>
            settings
          </ListItem>
        </a>

        <div
          className={`hover:bg-${
            colors?.length! > 1 && colors![0]?.toLowerCase()
          }-400 rounded-xl items-start justify-start`}
          // className={`hover:bg-${
          //   colors?.length! > 1 && colors![0]?.toLowerCase()
          // }-400 rounded-xl items-start justify-start`}
        >
          <div className="h-0.5 w-fit bg-white rounded-md" />
          <ListItem
            onClick={handleLogout}
            className="hover:bg-transparent hover:text-white text-white text-xs w-full"
          >
            <ListItemPrefix>
              <i className="fa fa-sign-out text-sm" aria-hidden="true"></i>
            </ListItemPrefix>
            Log Out
          </ListItem>
        </div>
      </List>
    </Card>
  );
}
