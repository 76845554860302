import React, { useEffect, useMemo, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Avatar,
  Button,
  IconButton,
  Input,
  Spinner,
  Tab,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import { useHistory } from "react-router-dom";

type PasswordType = {
  old_password: string;
  new_password: string;
  confirm_password: string;
};

const Settings = () => {
  const history = useHistory();
  const tabs = ["Change password", "Reset PIN", "Forgot PIN"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedFile, setSelectedFile] = useState<File | null | any>(null);
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");
  const [user, setUser] = useState<UserType>({
    id: "",
    name: "",
    phone: "",
    email: "",
    is_first_time: false,
    user_type: "",
    company_id: "",
    role_id: "",
  });

  const toggleType1 = () =>
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");

  const toggleType2 = () =>
    passwordType2 === "password"
      ? setPasswordType2("text")
      : setPasswordType2("password");

  const toggleType3 = () =>
    passwordType3 === "password"
      ? setPasswordType3("text")
      : setPasswordType3("password");

  const defaultValues = useMemo(() => {
    return {
      name: user?.name,
      phone: user?.phone,
      email: user?.email,
      is_first_time: user?.is_first_time,
      user_type: user?.user_type,
      company_id: user?.company_id,
      role_id: user?.role_id,
    };
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PasswordType>();

  const changePassword: SubmitHandler<PasswordType> = async (data) => {
    if (data?.new_password !== data?.confirm_password) {
      return toast.warn("New password and confirm password does not match");
    }
    try {
      const response = await fetch(`${BASEURL}/auth/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          old_password: data?.old_password,
          new_password: data?.new_password,
        }),
      });

      const req = await response.json();

      if (response.ok) {
        if (req?.error) {
          return toast.error(req?.data);
        }
        toast.success(req?.message ?? "Password has been changed succesfully");
        localStorage.clear();
        history?.push("/");
        window.location.reload();
      } else {
        toast.info(req?.data ?? "Something went wrong");
      }
    } catch (error) {
      toast.error("Error updating password");
    }
  };

  React.useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Settings" />

      <div className="grid grid-cols-1  lg:grid-cols-3 gap-5">
        <div className="w-full">
          <Tabs id="custom-animation" value={selectedTab}>
            <TabsHeader
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="flex flex-col py-2"
            >
              {tabs?.map((tab) => (
                <Tab
                  key={tab}
                  value={tab}
                  onClick={() => setSelectedTab(tab)}
                  className={`${
                    selectedTab === tab && "text-gray-900"
                  } place-items-start w-full py-2 text-sm font-semibold `}
                >
                  {tab}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>

        <div className="bg-white md:grid-cols-1 lg:col-span-2 rounded-lg p-5">
          {selectedTab === "Change password" ? (
            <form onSubmit={handleSubmit(changePassword)} className="w-full">
              <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                    type="password"
                  >
                    Old password
                  </Typography>
                  <Input
                    {...register("old_password", { required: true })}
                    crossOrigin="anonymous"
                    size="lg"
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                    type="password"
                  >
                    New password
                  </Typography>
                  <Input
                    {...register("new_password", { required: true })}
                    crossOrigin="anonymous"
                    size="lg"
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                    type="password"
                  >
                    Confirm password
                  </Typography>
                  <Input
                    crossOrigin="anonymous"
                    size="lg"
                    {...register("confirm_password", { required: true })}
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>

              <Button
                type="submit"
                className="mt-6 flex items-center justify-center"
              >
                {isSubmitting ? <Spinner className="w-4 h-4" /> : "Update"}
              </Button>
            </form>
          ) : selectedTab === "Reset PIN" ? (
            <form onSubmit={handleSubmit(changePassword)} className="w-full">
              <div className="mb-1 grid grid-cols-1 lg:grid-cols-2 gap-6">
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                  >
                    Old pin
                  </Typography>
                  <Input
                    {...register("old_password", { required: true })}
                    crossOrigin="anonymous"
                    size="lg"
                    maxLength={4}
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    minLength={4}
                  />
                </div>
                <div className="w-full">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-sm font-bold mb-3"
                  >
                    New pin
                  </Typography>
                  <Input
                    {...register("new_password", { required: true })}
                    crossOrigin="anonymous"
                    size="lg"
                    maxLength={4}
                    minLength={4}
                    className=" !border-blue-gray-200 focus:!border-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>

              <Button
                type="submit"
                className="mt-6 flex items-center justify-center"
              >
                {isSubmitting ? <Spinner className="w-4 h-4" /> : "Update"}
              </Button>
            </form>
          ) : selectedTab === "Forgot PIN" ? (
            <></>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Settings;
