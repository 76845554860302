import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import {
  CustomDeleteModal,
  CustomModal,
  DetailDrawer,
  HeaderIcon,
  RenderAdminDetail,
} from "../../../components";
import useSWR from "swr";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { AdminType, UpdateType } from "../../../types/admin";
import { ToastContainer, toast } from "react-toastify";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddAdminModal } from "../../../components/forms/add-admin";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { useHistory } from "react-router-dom";
import { BiBlock } from "react-icons/bi";
import { useQuery } from "@apollo/client";
import { GETPAYMENTAPPROVALS } from "../../../GraphQl/queries";
import moment from "moment";

// Define TypeScript types for the query response
type PaymentRequisition = {
  amount_requisitioned: number;
  created_at: string;
  initiation_type: string;
  payee_id: string;
  payee_type: string;
  rate: number;
  reference_amount: number;
};

type PolicyRequest = {
  amount: number;
  amended_rated_details: any; // Define type appropriately
  benefit_added_details: any; // Define type appropriately
  company_staff: {
    id: string;
    name: string;
    phone_number_one: string;
  };
  cover_type: string;
  businessClassByBusinessClass: {
    id: string;
    name: string;
  };
  risk_class: {
    id: string;
    name: string;
  };
  sub_agent: {
    first_name: string;
    id: string;
    last_name: string;
    phone_number: string;
  };
};

type Policy = {
  policy_number: string;
  status: string;
  debit_number: string;
  certificate_number: string;
  request: PolicyRequest;
};

type ApprovedPaymentRequisition = {
  amount: number;
  created_at: string;
  description: string;
  id: string;
  status: string;
  transaction_number: string;
  transaction_type: string;
  payment_requisition: PaymentRequisition;
  policy: Policy;
};

const PaymentApproval = () => {
  const history = useHistory();
  const [user, setUser] = useState<UserType>();
  const [requistionText, setRequistionSearch] = useState("");
  const [activeRequistionPage, setActiveRequistionPage] = useState(1);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<UpdateType>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [activeHistory, setActiveHistory] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const PAGE_SIZE = 10;

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  const {
    register: updateRegister,
    handleSubmit: updateSubmit,
    setValue,
    formState: { errors: updateError, isSubmitting: isUpdateSubmitting },
  } = useForm<UpdateType>({
    defaultValues: defaultValues,
  });

  const { data: requisition, loading: loadingRequistion } = useQuery<{
    approved_payment_requisitions: ApprovedPaymentRequisition[];
  }>(GETPAYMENTAPPROVALS, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const handleRequistionSerach = (e: any) => {
    setRequistionSearch(e.target.value);
    setActiveRequistionPage(1);
  };

  const filterData = (admin: ApprovedPaymentRequisition) => {
    const isTextMatch =
      admin?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.description
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.payment_requisition?.amount_requisitioned
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.payment_requisition?.initiation_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.payment_requisition?.payee_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.payment_requisition?.rate
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.payment_requisition?.reference_amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.debit_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.policy_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.amended_rated_details
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.businessClassByBusinessClass?.name
        ?.trim()
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.trim()?.toLowerCase()) ||
      admin?.policy?.request?.company_staff?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.company_staff?.phone_number_one
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.cover_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.risk_class?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.sub_agent?.last_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.sub_agent?.first_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      admin?.policy?.request?.sub_agent?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase());

    return isTextMatch;
  };

  const paginatedRequisitionData = requisition?.approved_payment_requisitions
    ?.filter(filterData)
    ?.slice((activeHistory - 1) * PAGE_SIZE, activeHistory * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (requisition?.approved_payment_requisitions?.filter(filterData).length ||
        0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  // Filtered and paginated data for the current page
  // const paginatedData = data?.admins
  //   ?.filter(filterData)
  //   .slice(
  //     (activeRequistionPage - 1) * PAGE_SIZE,
  //     activeRequistionPage * PAGE_SIZE
  //   );

  // const nextPage = () => {
  //   if (
  //     (activeRequistionPage - 1) * PAGE_SIZE <
  //     (data?.admins?.filter(filterData).length || 0)
  //   ) {
  //     setActiveRequistionPage(activeRequistionPage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (activeRequistionPage > 1) {
  //     setActiveRequistionPage(activeRequistionPage - 1);
  //   }
  // };

  useEffect(() => {
    let storage = localStorage.getItem("user");
    if (storage) {
      setUser(JSON.parse(storage));
    }
  }, []);

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value);
      });
    }
  }, [defaultValues]);

  const handleDelete = async () => {
    setOpenDrawer(false);
    try {
      setLoading(true);
      const response = await fetch(`${BASEURL}/auth/delete-admin`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          admin_id: selectedRow?.admin_id,
        }),
      });

      const req = await response.json();

      if (response.ok) {
        toast.success("Admin deleted successfully");
        SetShowDeleteModal(false);
        if (user?.email === selectedRow?.email) {
          localStorage.clear();
          history?.push("/");
          window.location.reload();
        }
      } else {
        toast.error(req?.msg);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error deleting admin");
      setLoading(false);
    }
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  React.useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setUser(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      />

      <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        children={<>{/* <RenderAdminDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Payment approvals" />

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of All Outstanding Payments Requisitions
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={requistionText}
                    onChange={handleRequistionSerach}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className="w-full bg-white min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Transaction Number",
                  "Payment Transaction Type",

                  "Description",
                  "Requisition Details",
                  "Policy Details",
                  "Premium Details",
                  "Amount",
                  "Created at",
                  "Status",
                  "",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedRequisitionData?.map((item, index: number) => {
                const isLast = index === paginatedRequisitionData?.length - 1;
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.id + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.transaction_number}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.transaction_type}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.description}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Amount requisitioned:{" "}
                        {(item?.payment_requisition.amount_requisitioned).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                        {/* <span className="font-normal">{item?.payment_requisition.amount_requisitioned}</span> */}
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Initiation Type:{" "}
                        <span className="font-normal">
                          {item?.payment_requisition.initiation_type}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Reference amount.:{" "}
                        {(item?.payment_requisition.reference_amount).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Policy Number:{" "}
                        <span className="font-normal">
                          {item?.transaction_type}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Certificate Number:{" "}
                        <span className="font-normal">
                          {item?.policy.certificate_number}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Debit Number:{" "}
                        <span className="font-normal">
                          {item?.policy.debit_number}{" "}
                        </span>
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Covertype:{" "}
                        <span className="font-normal">
                          {item?.policy?.request?.cover_type}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Premium amount:{" "}
                        <span className="font-normal">
                          {item?.policy?.request?.amount}
                        </span>
                      </Typography>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-bold text-xs capitalize text-left pl-7"
                      >
                        Created by:{" "}
                        <span className="font-normal">
                          {item?.policy?.request?.company_staff
                            ? item?.policy?.request?.company_staff
                                .phone_number_one
                            : item?.policy?.request?.sub_agent?.first_name +
                              " " +
                              item?.policy?.request?.sub_agent?.last_name}
                        </span>
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.policy?.request?.risk_class.name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.created_at).format("LLL")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <span
                        className={`${
                          item?.status?.toLowerCase() === "success"
                            ? "text-green-500 text-xs"
                            : "text-red-400 text-xs"
                        } `}
                      >
                        {item?.status.toLocaleLowerCase()}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {!paginatedRequisitionData && !loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {paginatedRequisitionData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (requisition?.approved_payment_requisitions?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (requisition?.approved_payment_requisitions?.filter(
                      filterData
                    ).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentApproval;
