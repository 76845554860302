import React, { useEffect, useMemo, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useHistory } from "react-router-dom";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaPlus, FaUsers } from "react-icons/fa6";
import {
  BASEURL,
  DEFAULTPRIMARYCOLOR,
  id_types,
  intermediary_types,
} from "../../../util";
import { useQuery } from "@apollo/client";
import {
  GETALLBANKS,
  GETALLBRANCHES,
  GETALLCOMMMISSIONS,
  GETALLDIVISIONSORWORKGROUPS,
  GETCOMPANYAGENTS,
  GETCOMPANYROLES,
  GETCOMPANYSTAFF,
} from "../../../GraphQl/queries";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { DASHBOARD_USERTYPE } from "../../../types/role";
import moment from "moment";
import { AgentDetail, CustomModal, DetailDrawer } from "../../../components";
import { UserType } from "../../../types/user";

type FormType = {
  id?: string;
  name: string;
  intermediary_type: string;

  intermediary_type_code: string;
  address: string;
  email: string;
  phone: string;
  branch_id: string;
  tin_number: string;
  momo_details: {
    network: string;
    number: string;
  };
  guarantor_details: {
    name: string;
    address: string;
    phone: string;
    id_number: string;
  };
  preferred_payment_channel: string;

  bank_details: {
    bank_name: string;
    acct_number: string;
    branch: string;
    account_name: string;
    code: string;
  };
  commission_id: string;
  settlement_mode: string;
  commisions_setup: [
    {
      commission_id: string;
      settlement_mode: string;
    }
  ];
  nic_registration_number: string;
  id_type: string;
  id_number: string;
  company_id: string;
  division_id: string;
  usertype: string;
  role_id: string;
  [key: string]: any;
};

export type ResponseType = {
  address: string;
  bank_details: {
    acct_number: string;
    bank_name: string;
    branch: string;
    account_name: string;
    code: string;
  };
  division_id: string;
  contact_person: string;
  preferred_payment_channel: string;
  cash_limit: string;
  phone_number: string;
  email: string;
  created_at: string;
  id: string;
  id_number: string;
  id_type: string;
  momo_details: {
    network: string;
    number: string;
  };

  agent_meta_data: {
    address: string;
    division_id: string;
    tin_number: string;
    bank_details: {
      acct_number: string;
      bank_name: string;
      branch: string;
      account_name: string;
      code: string;
    };
    id_number: string;
    id_type: string;
    momo_details: {
      network: string;
      number: string;
    };


  }
  name: string;
  first_name: string;
  last_name: string;
  intermediary_type: string;
  nic_registration_number: string;
  phone: string;
  registration_number: string;
  tin_number: string;
  branch: {
    id: string;
    code: string;
    name: string;
  };
  sub_agents: {
    role_id: string;
  }[];
};

const Agents = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState<ResponseType>();
  const handleOpen = (data: ResponseType) => {
    setOpen(!open);
    setSelectedRow(data);
  };

  const formattedUserString = localStorage.getItem("user");
  const formattedUser = formattedUserString ? JSON.parse(formattedUserString) : null;

  let companyName: string | null = null; // Initialize with null
  if (formattedUser && typeof formattedUser === "object") {
    companyName = formattedUser.name;
  }

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    };
  }, [showUpdateModal]);

  let PAGE_SIZE = 20;

  const { data: commissions, loading: loadingCommissions } = useQuery<{
    commisions: {
      description: string;
      id: string;
      name: string;
      status: string;
      created_at: string;
    }[];
  }>(GETALLCOMMMISSIONS);

  const { data: roles, loading: loadingRoles } = useQuery<{
    company_role: {
      created_at: string;
      description: string;
      id: string;
      name: string;
    }[];
  }>(GETCOMPANYROLES, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const {
    data: agents,
    loading: loadingAgents,
    refetch: refreshAgent,
  } = useQuery<{
    sub_agents: ResponseType[];
  }>(GETCOMPANYAGENTS, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const {
    data: banks,
    loading: loadingBanks,
    refetch: refechBanks,
  } = useQuery<{
    bank: {
      code: string;
      id: string;
      name: string;
    }[];
  }>(GETALLBANKS);

  const { data: divisions, loading: loadingDivisions } = useQuery<{
    divisions: {
      id: string;
      name: string;
    }[];
  }>(GETALLDIVISIONSORWORKGROUPS, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const {
    data: branches,
    loading,
    refetch,
  } = useQuery<{
    branch: {
      address: string;
      code: string;
      id: string;
      name: string;
    }[];
  }>(GETALLBRANCHES, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue: registersetValue,
    control,
    formState: { isSubmitting },
  } = useForm<FormType>({
    defaultValues: {
      commisions_setup: [
        {
          commission_id: "",
          settlement_mode: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commisions_setup",
  });

  const {
    register: updateRegister,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdate,
    formState: { isSubmitting: updating, errors: updateError },
    setValue: setUpdateValue,
  } = useForm<FormType>({
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const intermediary_type = intermediary_types?.find(
      (item) => item?.code === data?.intermediary_type_code
    );
    if (data?.role_id === "Select role") {
      return toast.error("Select a valid role");
    }

    if (data?.branch_id === "Select Branch") {
      return toast.error("Select a valid branch");
    }
    if (data?.division_id === "Select division") {
      return toast.error("Select a valid division");
    }
    try {
      let stringifyData = {
        name: data?.name,
        intermediary_type: "agent",
        intermediary_type_code: "Agent",
        address: data?.address,
        email: data?.email,
        phone: data?.phone,
        branch_id: data?.branch_id,
        tin_number: data?.tin_number,
        preferred_payment_channel: data?.preferred_payment_channel,
        guarantor_details: {
          name: data?.guarantor_details?.name,
          address: data?.guarantor_details?.address,
          phone: data?.guarantor_details?.phone,
          id_number: data?.guarantor_details?.id_number,
        },
        momo_details: {
          network: data?.momo_details?.network,
          number: data?.momo_details?.number,
        },
        bank_details: {
          bank_name: data?.bank_details?.bank_name,
          acct_number: data?.bank_details?.acct_number,
          branch: data?.bank_details?.branch,
          account_name: data?.bank_details.account_name,
          code: data?.bank_details.code,
        },
        nic_registration_number: data?.nic_registration_number,
        id_type: data?.id_type,
        id_number: data?.id_number,
        intermediary_id: localStorage.getItem("company_id"),
        division_id: data?.division_id,
        usertype: DASHBOARD_USERTYPE.company,
        role_id: data?.role_id,
        commisions_setup: data?.commisions_setup,
      };

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(stringifyData),
      };

      const req = await fetch(`${BASEURL}/create-new-agent-broker`, options);
      const res = await req.json();

      if (req.ok) {
        if (res?.data?.id) {
          toast.success(res?.message ?? "Agent account created successfully");
          refreshAgent();
          reset();
        } else {
          toast.error(res?.detail ?? res?.data);
        }
      } else {
        toast.error(res?.message ?? "An error occured");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.intermediary_type
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.phone?.toLowerCase()?.includes(searchText?.toLowerCase());

    return isTextMatch;
  };



  // Filtered and paginated data for the current page
  const paginatedData = agents?.sub_agents
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (agents?.sub_agents?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const deleteAgent = async (agent_id: string) => {
    try {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          agent_id,
          usertype: DASHBOARD_USERTYPE.company,
        }),
      };

      const req = await fetch(`${BASEURL}/delete-agent`, options);

      const res = await req.json();

      if (req.ok) {
        toast.success(res?.message ?? "Agent deleted successfully");
        refreshAgent();
      } else {
        toast.error(res?.message ?? "An error occured");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleUpdate: SubmitHandler<FormType> = async (data) => {
    if (data?.branch_id?.toLowerCase() === "select branch") {
      return toast.error("Select branch");
    }
    if (data?.division_id?.toLowerCase() === "select division") {
      return toast.error("Select division");
    }
    try {
      let stringifyData = {
        name: data?.name,
        agent_id: selectedRow?.id,
        intermediary_type: "agent",
        intermediary_type_code: "Agent",
        address: data?.address,
        email: data?.email,
        phone: data?.phone,
        branch_id: data?.branch_id,
        preferred_payment_channel: data?.preferred_payment_channel,
        tin_number: data?.tin_number,
        momo_details: {
          network: data?.momo_details?.network,
          number: data?.momo_details?.number,
        },
        bank_details: {
          bank_name: data?.bank_details.bank_name,
          acct_number: data?.bank_details.acct_number,
          branch: data?.bank_details.branch,
          account_name: data?.bank_details.account_name,
          code: data?.bank_details.code,
        },
        nic_registration_number: data?.nic_registration_number,
        id_type: data?.id_number,
        id_number: data?.id_number,
        company_id: localStorage.getItem("company_id"),
        division_id: data?.division_id,
        usertype: DASHBOARD_USERTYPE.company,
        role_id: selectedRow?.sub_agents[0]?.role_id,
      };

      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(stringifyData),
      };


      const req = await fetch(`${BASEURL}/edit-new-agent`, options);

      const res = await req.json();

      if (req.ok) {
        if (res?.data?.id) {
          toast.success(res?.message ?? "Agent account updated successfully");
          refreshAgent();
          resetUpdate();
          setUpdateModal(false);
        } else {
          toast.error(res?.detail ?? res?.data);
        }
      } else {
        toast.error(res?.message ?? "An error occured");
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setUpdateValue(key as keyof typeof defaultValues as any, value);
      });
    }
  }, [defaultValues]);

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Agents registration" />

      <DetailDrawer
        onClose={() => setOpen(false)}
        open={open}
        title="Agent Detail"
        size={400}
        children={<AgentDetail content={selectedRow} />}
      />

      {/* update modal */}
      <CustomModal
        onClose={() => setUpdateModal(false)}
        open={showUpdateModal}
        size={"xl"}
        title="Update Agent"
        className="h-full overflow-scroll scrolls"
        children={
          <>
            <ToastContainer />
            <div>
              <form onSubmit={handleUpdateSubmit(handleUpdate)}>
                <div className="scrolls w-full">
                  <div className="">
                    <div className="mt-5 flex flex-col items-end justify-end">
                      <div className="w-full">
                        <div className="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Role
                            </Typography>
                            <select
                              {...updateRegister("role_id", { required: true })}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option disabled defaultChecked>
                                {loadingRoles ? "Loading roles" : "Select role"}
                              </option>
                              {roles?.company_role?.map((role) => (
                                <option
                                  key={role?.id}
                                  label={role?.name}
                                  value={role?.id}
                                >
                                  {role?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Client type
                            </Typography>
                            <select
                              {...updateRegister("intermediary_type_code", {
                                required: true,
                              })}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              {intermediary_types?.map((intermediary_type) => (
                                <option
                                  // disabled={
                                  //   intermediary_type?.code !== "01"
                                  //     ? true
                                  //     : false
                                  // }
                                  label={intermediary_type?.name}
                                  value={intermediary_type?.code}
                                >
                                  {intermediary_type?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Name
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("name", { required: true })}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Address
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("address", { required: true })}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Phone number
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("phone", { required: true })}
                            />
                          </div>

                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Email
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("email", { required: true })}
                            />
                          </div>
                          {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Cash limit
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("cash_limit", {
                                required: true,
                              })}
                            />
                          </div> */}
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              TIN
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("tin_number", {
                                required: true,
                              })}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              NIC Registration number
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("nic_registration_number", {
                                required: true,
                              })}
                            />
                          </div>
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              National id type
                            </Typography>
                            <select
                              {...updateRegister("id_type", { required: true })}
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              {id_types?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  label={item?.name}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              ID number
                            </Typography>
                            <Input
                              crossOrigin="anonymous"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("id_number", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="p-3 bg-white rounded-b-xl">
                        <div className="mt-5 flex flex-col items-end justify-end">
                          <div className="w-full">
                            <div className="mb-6 flex flex-col gap-2">
                              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                                <Typography
                                  variant="h6"
                                  color="blue-gray"
                                  className="mb-2 font-semibold text-[14px] w-1/3"
                                >
                                  Branch
                                </Typography>
                                <select
                                  {...updateRegister("branch_id", {
                                    required: true,
                                  })}
                                  placeholder="Division"
                                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  defaultValue={defaultValues?.branch?.id}
                                  value={defaultValues?.branch?.id}
                                >
                                  <option selected defaultChecked>
                                    {loading ? "Loading..." : "Select Branch"}
                                  </option>
                                  {branches?.branch?.map((item) => (
                                    <option
                                      key={item?.id}
                                      value={item?.id}
                                      label={`${item?.name} (${item?.code})`}
                                    >
                                      {item?.name} (${item?.code})
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                                <Typography
                                  variant="h6"
                                  color="blue-gray"
                                  className="mb-2 font-semibold text-[14px] w-1/3"
                                >
                                  Division
                                </Typography>
                                <select
                                  defaultValue={defaultValues?.division_id}
                                  value={defaultValues?.division_id}
                                  {...updateRegister("division_id", {
                                    required: true,
                                  })}
                                  placeholder="Division"
                                  className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                  <option selected defaultChecked>
                                    {loadingDivisions
                                      ? "Loading..."
                                      : "Select division"}
                                  </option>
                                  {divisions?.divisions?.map((item) => (
                                    <option value={item?.id} label={item?.name}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Bank Detials
                        </Typography>
                        <hr className="my-1" />
                        <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                          <Typography
                            variant="h6"
                            color="blue-gray"
                            className="mb-2 font-semibold text-[14px] w-1/3"
                          >
                            Bank transfer
                          </Typography>
                          <div className="w-full flex flex-col my-1 gap-2">
                            <select
                              {...updateRegister("bank_details.bank_name", {
                                required: true,
                              })}
                              onChange={(e) => {
                                const selectedBankCode =
                                  e.target.options[
                                    e.target.selectedIndex
                                  ].getAttribute("data-code");
                                setUpdateValue(
                                  "bank_details.code",
                                  selectedBankCode ? selectedBankCode : ""
                                );
                              }}
                              placeholder="Bank name"
                              className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option selected defaultChecked>
                                {loadingBanks ? "Loading..." : "Select Bank"}
                              </option>
                              {banks?.bank?.map((item) => (
                                <option
                                  key={item?.code}
                                  value={item?.name}
                                  data-code={item?.code}
                                  label={`${item?.name}`}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account name"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.account_name", {
                                required: true,
                              })}
                            />
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Branch"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.branch", {
                                required: true,
                              })}
                            />
                            <Input
                              crossOrigin="anonymous"
                              placeholder="Account number"
                              size="md"
                              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                              labelProps={{
                                className:
                                  "before:content-none after:content-none",
                              }}
                              {...updateRegister("bank_details.acct_number", {
                                required: true,
                              })}
                            />
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Mobile Money Details
                        </Typography>
                        <hr className="my-1" />
                        <div className="mt-3">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Wallet Type
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                {...updateRegister("momo_details.network", {
                                  required: true,
                                })}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option label="MTN" value={"MTN"}>
                                  MTN
                                </option>
                                <option label="AirtelTigo" value={"AirtelTigo"}>
                                  AirtelTigo
                                </option>
                                <option label="Vodafone" value={"Vodafone"}>
                                  Vodafone
                                </option>
                              </select>
                              <Input
                                {...updateRegister("momo_details.number", {
                                  required: true,
                                })}
                                crossOrigin="anonymous"
                                placeholder="Wallet Number"
                                size="md"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                  className:
                                    "before:content-none after:content-none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <Typography className="text-md text-gray-600">
                          Preferred payment Method
                        </Typography>
                        <hr className="my-1" />
                        <div className="mt-3">
                          <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                            <Typography
                              variant="h6"
                              color="blue-gray"
                              className="mb-2 font-semibold text-[14px] w-1/3"
                            >
                              Payment Method
                            </Typography>
                            <div className="w-full flex flex-col gap-2 my-1">
                              <select
                                {...updateRegister(
                                  "preferred_payment_channel",
                                  { required: true }
                                )}
                                className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              >
                                <option label="Momo" value={"momo"}>
                                  Momo
                                </option>
                                <option label="Bank" value={"bank"}>
                                  Bank
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => setUpdateModal(false)}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center"
                    variant="gradient"
                    color="green"
                  >
                    {updating ? (
                      <Spinner className="h-4 w-4 text-white" />
                    ) : (
                      <span>Update</span>
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </>
        }
      />

      <div className="">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5"
        >
          <div className=" rounded-lg overflow-hidden w-full">
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="p-3"
            >
              <Typography className="text-base text-white">
                User Details
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Role
                      </Typography>
                      <select
                        {...register("role_id", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option defaultChecked>
                          {loadingRoles ? "Loading roles" : "Select role"}
                        </option>
                        {roles?.company_role?.map((role) => (
                          <option
                            key={role?.id}
                            label={role?.name}
                            value={role?.id}
                          >
                            {role?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Client type
                      </Typography>
                      <select
                        {...register("intermediary_type_code", {
                          required: false,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {intermediary_types?.map((intermediary_type) => (
                          <option
                            disabled={
                              intermediary_type?.code !== "01" ? true : false
                            }
                            label={intermediary_type?.name}
                            value={intermediary_type?.code}
                          >
                            {intermediary_type?.name}
                          </option>
                        ))}
                      </select>
                    </div> */}
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("name", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Address
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("address", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("phone", { required: true })}
                      />
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Email
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("email", { required: true })}
                      />
                    </div>
                    {/* <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Cash limit
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("cash_limit", { required: true })}
                      />
                    </div> */}
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        TIN
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("tin_number", { required: true })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        NIC Registration number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("nic_registration_number", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        National id type
                      </Typography>
                      <select
                        {...register("id_type", { required: true })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        {id_types?.map((item) => (
                          <option
                            key={item?.code}
                            value={item?.name}
                            label={item?.name}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        ID number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("id_number", { required: true })}
                      />
                    </div>
                    <Typography className="text-md text-gray-600">
                      Guarantor Details
                    </Typography>
                    <hr className="my-1" />
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Guarantor name
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("guarantor_details.name", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Address
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("guarantor_details.address", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Phone number
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("guarantor_details.phone", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Guarantor ID
                      </Typography>
                      <Input
                        crossOrigin="anonymous"
                        size="md"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        {...register("guarantor_details.id_number", {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" rounded-lg overflow-hidden w-full">
            <div
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="p-3"
            >
              <Typography className="text-base text-white">
                Branch and Division
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <div className="mt-5 flex flex-col items-end justify-end">
                <div className="w-full">
                  <div className="mb-6 flex flex-col gap-2">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Branch
                      </Typography>
                      <select
                        {...register("branch_id", { required: true })}
                        placeholder="Division"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loading ? "Loading..." : "Select Branch"}
                        </option>
                        {branches?.branch?.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            label={`${item?.name} (${item?.code})`}
                          >
                            {item?.name} (${item?.code})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-1 gap-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Division
                      </Typography>
                      <select
                        {...register("division_id", { required: true })}
                        placeholder="Division"
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loadingDivisions ? "Loading..." : "Select division"}
                        </option>
                        {divisions?.divisions?.map((item) => (
                          <option value={item?.id} label={item?.name}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Bank Detials
              </Typography>
              <hr className="my-1" />
              <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-2 font-semibold text-[14px] w-1/3"
                >
                  Bank transfer
                </Typography>
                <div className="w-full flex flex-col my-1 gap-2">
                  <select
                    {...register("bank_details.bank_name", { required: true })}
                    onChange={(e) => {
                      const selectedBankCode =
                        e.target.options[e.target.selectedIndex].getAttribute(
                          "data-code"
                        );
                      registersetValue(
                        "bank_details.code",
                        selectedBankCode ? selectedBankCode : ""
                      );
                    }}
                    placeholder="Bank name"
                    className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected defaultChecked>
                      {loadingBanks ? "Loading..." : "Select Bank"}
                    </option>
                    {banks?.bank?.map((item) => (
                      <option
                        key={item?.code}
                        value={item?.name}
                        data-code={item?.code}
                        label={`${item?.name}`}
                      >
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <Input
                    crossOrigin="anonymous"
                    placeholder="Account name"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.account_name", {
                      required: true,
                    })}
                  />
                  <Input
                    crossOrigin="anonymous"
                    placeholder="Branch"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.branch", { required: true })}
                  />
                  <Input
                    crossOrigin="anonymous"
                    placeholder="Account number"
                    size="md"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    {...register("bank_details.acct_number", {
                      required: true,
                    })}
                  />
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Mobile Money Details
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Wallet Type
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("momo_details.network", { required: true })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="MTN" value={"MTN"}>
                        MTN
                      </option>
                      <option label="AirtelTigo" value={"AirtelTigo"}>
                        AirtelTigo
                      </option>
                      <option label="Vodafone" value={"Vodafone"}>
                        Vodafone
                      </option>
                    </select>
                    <Input
                      {...register("momo_details.number", { required: true })}
                      crossOrigin="anonymous"
                      placeholder="Wallet Number"
                      size="md"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Preferred payment Method
              </Typography>
              <hr className="my-1" />
              <div className="mt-3">
                <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                  <Typography
                    variant="h6"
                    color="blue-gray"
                    className="mb-2 font-semibold text-[14px] w-1/3"
                  >
                    Payment Method
                  </Typography>
                  <div className="w-full flex flex-col gap-2 my-1">
                    <select
                      {...register("preferred_payment_channel", {
                        required: true,
                      })}
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option label="Momo" value={"momo"}>
                        Momo
                      </option>
                      <option label="Bank" value={"bank"}>
                        Bank
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <Typography className="text-md text-gray-600">
                Commission Setup
              </Typography>
              <hr className="my-1" />{" "}
              {fields.map((commisions_setup, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-2 pb-5 mb-5 border-b border-gray-200"
                >
                  <div className="mt-3 w-full">
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className=" font-semibold text-[14px] w-1/3"
                      >
                        Commission Type
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          {...register(
                            `commisions_setup.${index}.commission_id`,
                            { required: true }
                          )}
                          defaultValue={commisions_setup.commission_id}
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected defaultChecked>
                            {loadingCommissions
                              ? "Loading..."
                              : "Select commission"}
                          </option>
                          {commissions?.commisions?.map((item) => (
                            <option
                              key={item?.id}
                              value={item?.id}
                              label={`${item?.name}`}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-col lg:flex-row my-2">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="font-semibold text-[14px] w-1/3"
                      >
                        Select mode of settlement
                      </Typography>
                      <div className="w-full flex flex-col gap-2 my-1">
                        <select
                          placeholder="Mode of settlement"
                          {...register(
                            `commisions_setup.${index}.settlement_mode`,
                            { required: true }
                          )}
                          defaultValue={commisions_setup.commission_id}
                          className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected defaultChecked>
                            {loadingCommissions
                              ? "Loading..."
                              : "Select settlement mode"}
                          </option>
                          <option
                            label="Instant"
                            className="capitalize"
                            value={"Instant"}
                          >
                            Instant
                          </option>
                          <option
                            label="manual"
                            className="capitalize"
                            value={"manual"}
                          >
                            Manual
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Button
                      className={`${index === 0 && "mt-6"}`}
                      size="md"
                      color="red"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <BiTrash />
                    </Button>
                  </div>
                </div>
              ))}
              <Button
                className="mt-4 flex items-center gap-1 capitalize font-normal"
                type="button"
                size="sm"
                onClick={() =>
                  append({
                    commission_id: "",
                    settlement_mode: "",
                  })
                }
              >
                <FaPlus />
                <span>Add commission</span>
              </Button>
              <div className="flex items-end justify-end">
                <Button
                  type="submit"
                  size="sm"
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="flex flex-row items-center font-normal"
                >
                  <BiPlus size={20} />

                  {isSubmitting ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Create client"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>

        <div className="mt-10 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base">
              List of Agents of {companyName}
            </Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="">
                  <tr>
                    {[
                      "name",
                      "email",
                      "Phone number",
                      "TIN",
                      "Bank Details",
                      "Momo Details",
                      "Date created",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "px-4 py-2  text-center"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200 hover:cursor-pointer"
                      >
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.first_name + " " + item?.last_name}
                          </Typography>
                        </td>

                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.email}
                          </Typography>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.phone_number}
                          </Typography>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {item?.agent_meta_data?.tin_number}
                          </Typography>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Bank:{" "}
                              <span className="font-normal">
                                {item?.agent_meta_data?.bank_details?.bank_name}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Branch:{" "}
                              <span className="font-normal">
                                {item?.agent_meta_data?.bank_details?.branch}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Network:{" "}
                              <span className="font-normal">
                                {item?.agent_meta_data?.momo_details?.network}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              number:{" "}
                              <span className="font-normal">
                                {item?.agent_meta_data?.momo_details?.number}
                              </span>
                            </Typography>
                          </div>
                        </td>
                        <td
                          className={classes}
                          onClick={() => handleOpen(item)}
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize "
                          >
                            <span className="font-normal">
                              {moment(item?.created_at).format("LLL")}
                            </span>
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Button
                            size="sm"
                            color="teal"
                            className="text-xs font-normal capitalize bg-brand-teal"
                            onClick={() => {
                              const branch_name = branches?.branch?.find(
                                (branch) => item?.branch?.id === branch?.id
                              );

                              localStorage.setItem(
                                "agent",
                                JSON.stringify({
                                  id: item?.id,
                                  name: item?.name,
                                  phone_number: item?.phone,
                                  role_id: item?.sub_agents[0]?.role_id,
                                  intermediary_type: item?.intermediary_type,
                                  branch: item?.branch?.name,
                                })
                              );
                              history.push("/dashboard/setup/manage-agent");
                              window.location.reload();
                            }}
                          >
                            Manage agent
                          </Button>
                          <span className="mx-1"></span>
                          <Button
                            onClick={() => {
                              setSelectedRow(item);
                              setUpdateModal(true);
                            }}
                            size="sm"
                            color="teal"
                            className="text-xs font-normal capitalize bg-brand-green"
                          >
                            Edit
                          </Button>
                          <span className="mx-1"></span>
                          <IconButton
                            onClick={() => deleteAgent(item?.id)}
                            color="red"
                            size="sm"
                          >
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loadingAgents && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loadingAgents && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-900">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-900">
                      {Math.ceil(
                        (agents?.sub_agents?.filter(filterData).length || 0) /
                        PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="outlined"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (agents?.sub_agents?.filter(filterData).length || 0) /
                        PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default Agents;
