import React, { useEffect, useState } from "react";
import { CustomDeleteModal } from "../../../components";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  Input,
  Typography,
  Spinner,
  Tooltip,
  IconButton,
} from "@material-tailwind/react";
import { DEFAULTPRIMARYCOLOR } from "../../../util";
import { UserType } from "../../../types/user";
import { GETALLSAVEDQUOTATIONS } from "../../../GraphQl/queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

type BusinessClass = {
  name: string;
  id: string;
};

type CompanyStaff = {
  id: string;
  name: string;
  phone_number_one: string;
};

type SubAagent = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
};

type RiskClass = {
  id: string;
  name: string;
};

type VehicleDetailsType = {
  color: string;
  body_type: string; // Assuming this is an identifier for the body type
  vehicle_model: string; // Assuming this is an identifier for the vehicle model
  vehicle_value: number;
  chassis_number: string;
  make_of_vehicle: string; // Assuming this is an identifier for the make of the vehicle
  number_of_seats: number;
  accessories_value: number;
  registration_number: string;
  year_of_manufacture: string;
};

type SavedQuotationType = {
  request: {
    amended_rated_details: string;
    amount: string;
    benefit_added_details: string;
    businessClassByBusinessClass: BusinessClass;
    cover_type: string;
    created_at: string;
    sub_agent: SubAagent;
    currency: string;
    company_staff: CompanyStaff;
    duration: number;
    end_date: string;
    id: string;
    is_paid: boolean;
    payment_channel: string;
    request_stage: string;
    registration_number_text: string;
    start_date: string;
    user_type: string;
    vehicle_details: VehicleDetailsType;
    risk_class: RiskClass;
  };
};

const QuotationView = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const handleOpen = () => setOpen(!open);
  const PAGE_SIZE = 10;

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  const { data, loading, refetch } = useQuery<{
    request: SavedQuotationType[];
  }>(GETALLSAVEDQUOTATIONS, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const filterData = (filter: SavedQuotationType) => {
    const isTextMatch =
      filter?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.request?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.amended_rated_details
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.businessClassByBusinessClass?.name
        ?.trim()
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.trim()?.toLowerCase()) ||
      filter?.request?.company_staff?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.company_staff?.phone_number_one
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.cover_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.amount
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.payment_channel
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.risk_class?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.request_stage
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.duration
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.last_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.first_name
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.sub_agent?.phone_number
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.request?.user_type
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());

    return isTextMatch;
  };

  const paginatedData = data?.request
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.request?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  return (
    <>
      {/* <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={() => {}}
        loading={loading}
        title="Delete selected admin"
      /> */}
      <CustomHeader title="Saved Quotations" />

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4 font-bold  leading-none opacity-70 capitalize"
            >
              Total Quotations{" "}
              {/* {data?.admins && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.request?.length}
                </span>
              )} */}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
            <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
              <div className="w-full">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
          </div>
          <table className=" bg-white w-full min-w-max table-auto">
            <thead className="">
              <tr>
                {[
                  "Business class",
                  "Risk class",
                  "Covertype",
                  "premium amount",
                  "start date",
                  "end date",
                  "payment channel",
                  "created at",
                  "staff/agent",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item: any, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setSelectedRow(item);
                      // setModalOpen(true);
                    }}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.businessClassByBusinessClass.name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.risk_class.name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.cover_type}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS{" "}
                        {parseFloat(item?.amount ?? 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs "
                      >
                        {moment(item?.start_date).format("LL")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.end_date).format("LL")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.payment_channel}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.created_at).format("LL")}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <div className="flex flex-col gap-2 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Full name:{" "}
                          <span className="font-normal">
                            {item?.company_staff
                              ? `${item?.company_staff?.name}`
                              : `${item?.sub_agent?.first_name} ${item?.sub_agent?.last_name}`}
                          </span>
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Phone number:{" "}
                          <span className="font-normal">
                            {item?.company_staff
                              ? item?.company_staff?.phone_number_one
                              : item?.sub_agent?.phone_number}
                          </span>
                        </Typography>

                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          User type:{" "}
                          <span className="font-normal">
                            {item?.company_staff
                              ? "Company Staff"
                              : "Sub Agent"}
                          </span>
                        </Typography>
                      </div>
                    </td>

                    <td className={classes}>
                      <Tooltip content="Edit Admin">
                        <Button
                          color="green"
                          size="sm"
                          className="text-xs font-normal capitalize"
                          onClick={() => {
                            setSelectedRow(item);
                            // setModalOpen(true);
                          }}
                          variant="filled"
                        >
                          View More
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}

          {!paginatedData && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography variant="h6">No data found</Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (data?.request?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                color="white"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (data?.request?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuotationView;
