import React, { useState, useEffect } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  CardHeader,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiSearch } from "react-icons/bi";
import { FiDownloadCloud } from "react-icons/fi";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import useSWR from "swr";
import dayjs from "dayjs";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import {
  COMMISSIONREPORT,
  Customers,
  GETREPORTDATA,
  GETSUBSCRIPTION,
  GETTAXREPORT,
  POLICYREPORT,
} from "../../../GraphQl/queries";
import { ReportType } from "../../../types/query";
import {
  Commissions_HEADERS,
  Customers_HEADERS,
  Policy_HEADERS,
  Subscriptions_Table_Head,
  Table_Headers,
  Tax_HEADERS,
} from "./data";
import moment from "moment";
import { UserType } from "../../../types/user";

type CustomerType = {
  customer_type: string;
  email: string;
  first_name: string;
  ghana_card: string;
  id: string;
  last_name: string;
  phone_number: string;
  created_at: string;
};

type SubscriptionType = {
  amount: number;
  datecreated: string;
  paid_by: any;
  reference_number: string;
  status: string;
  subscription_amount: number;
  policy: {
    policy_number: string;
    request: {
      amount: string;
      businessClassByBusinessClass: {
        id: string;
        name: string;
      };
      cover_type: string;
    };
  };
};

type TaxType = {
  amount: number;
  created_at: string;
  rate: number;
  id: string;
  tax: {
    id: string;
    name: string;
  };
  commissions_payable: {
    commission_amount: number;
    rate: string;
    reference_amount: number;
    status: string;
    commision: {
      id: string;
      name: string;
    };
  };
};
type CommissionsType = {
  account_details: {
    code: string;
    branch: string;
    bank_name: string;
    acct_number: string;
    account_name: string;
  };
  created_by: any;
  payee_details: string;
  payee_name: string;
  payee_number: string;
  payee_type: string;
  payment_stage: string;
  provider_transaction_id: string;
  rate: string;
  reference: string;
  reference_amount: number;
  status: string;
  transaction_name: string;
  transaction_category: string;
  transaction_type_id: string;
  policy_id: string;
};

type PolicyType = {
  certificate_number: number;
  debit_number: number;
  policy_number: number;
  request: {
    amount: number;
    payment_channel: string;
    sub_agent: {
      email: string;
      first_name: string;
      id: string;
      last_name: string;
    };
    businessClassByBusinessClass: {
      id: string;
      name: string;
    };
    company_staff: {
      id: string;
      name: string;
    };
    cover_type: string;
  };
};

const ReportView = () => {
  const options = [
    "policy",
    "taxes payment",
    "Commissions",
    "Customers",
    "Company",
  ];
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState("policy");
  const [tableHead, setTableHead] = useState<string[]>(
    Subscriptions_Table_Head
  );

  const PAGE_SIZE = 10;
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [startDate, setStartDate] = useState("2023-04-01T00:00:00Z");
  const [endDate, setEndDate] = useState("2024-04-30T23:59:59Z");

  const { data: customer, loading: loadingCustomer } = useQuery<{
    customers: CustomerType[];
  }>(Customers, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const { data: subscriptionsData, loading: loadingSubscriptionsData } =
    useQuery<{ system_client_payment: SubscriptionType[] }>(GETSUBSCRIPTION, {
      variables: {
        company_id: localStorage.getItem("company_id"),
      },
    });

  const { data: tax_payable, loading: loadingTaxPayable } = useQuery<{
    tax_payable: SubscriptionType[];
  }>(GETTAXREPORT, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const { data: commissionData, loading: loadingCommission } = useQuery<{
    view_payment_requistion_details: CommissionsType[];
  }>(COMMISSIONREPORT, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const { data: policyData, loading: loadingPolicy } = useQuery<{
    policy: PolicyType[];
  }>(POLICYREPORT, {
    variables: {
      id: localStorage.getItem("company_id"),
    },
  });

  const handleFilterChange = (value: string) => {
    switch (value) {
      case "subscriptions":
        setTableHead(Subscriptions_Table_Head);
        break;
      case "taxes payment":
        setTableHead(Tax_HEADERS);
        break;
      case "Commissions":
        setTableHead(Commissions_HEADERS);
        break;
      case "policy":
        setTableHead(Policy_HEADERS);
        break;
      case "Customers":
        setTableHead(Customers_HEADERS);
        break;
      default:
        setTableHead([]);
        break;
    }
    setFilter(value);
  };

  useEffect(() => {
    // Replace your mock data with the actual SWR data
    const selectedData =
      filter === "subscriptions"
        ? subscriptionsData?.system_client_payment
        : filter === "taxes payment"
        ? tax_payable?.tax_payable
        : filter === "policy"
        ? policyData?.policy
        : filter === "Customers"
        ? customer?.customers
        : filter === "Commissions"
        ? commissionData?.view_payment_requistion_details
        : [];

    const filteredItems = selectedData
      ? (selectedData as any[]).filter((item: any) => {
          if (filter === "subscriptions") {
            // Adjust the filtering logic for the Brand type
            const amount = item?.amount
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const insurer_company_email = item?.subscription_amount
              ?.toString()
              ?.includes(searchTerm);
            const insurer_company_name = item?.policy?.request?.amount
              ?.toString()
              ?.includes(searchTerm);
            const intemediary_ =
              item?.policy?.request?.businessClassByBusinessClass?.name.includes(
                searchTerm
              );
            const intemediary_email =
              item?.policy?.request?.cover_type.includes(searchTerm);
            const intemediary_name = item?.reference_number
              ?.toString()
              ?.includes(searchTerm);

            return (
              amount ||
              insurer_company_email ||
              insurer_company_name ||
              intemediary_ ||
              intemediary_email ||
              intemediary_name
            );
          } else if (filter === "taxes payment") {
            // Adjust the filtering logic for the clients
            const address = item?.amount
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const client_type = item?.commissions_payable?.commision?.name
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const company_person_name =
              item?.commissions_payable?.commission_amount
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
            const phone_number = item?.commissions_payable?.rate
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const brandNameMatches = item?.commissions_payable?.rate
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const network = item?.commissions_payable?.reference_amount
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const name = item?.commissions_payable?.commission_amount
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const phone = item?.tax?.name
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());

            return (
              address ||
              client_type ||
              company_person_name ||
              phone_number ||
              brandNameMatches ||
              network ||
              name ||
              phone
            );
          } else if (filter === "Customers") {
            // Adjust the filtering logic for the policies sold
            const nameMatches1 = item?.customer_type
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const policy_number = item?.email
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches2 = item?.first_name
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches3 = item?.last_name
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches4 = item?.phone_number
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());

            return (
              policy_number ||
              nameMatches1 ||
              nameMatches2 ||
              nameMatches3 ||
              nameMatches4
            );
          } else if (filter === "Commissions") {
            // Adjust the filtering logic for the policies sold
            const nameMatches1 = item?.account_details?.account_name
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const policy_number = item?.payee_details
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches2 = item?.payee_name
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches3 = item?.payee_number
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches4 = item?.payee_type
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches5 = item?.payment_stage
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches6 = item?.rate
              ?.toString()
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches7 = item?.reference_amount
              ?.toString()
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches8 = item?.transaction_category
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches9 = item?.transaction_name
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());

            return (
              policy_number ||
              nameMatches1 ||
              nameMatches2 ||
              nameMatches3 ||
              nameMatches4 ||
              nameMatches5 ||
              nameMatches6 ||
              nameMatches7 ||
              nameMatches8 ||
              nameMatches9
            );
          } else if (filter === "policy") {
            // Adjust the filtering logic for the policies sold
            const nameMatches1 = item?.debit_number
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const policy_number = item?.debit_number
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches2 = item?.certificate_number
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches3 = item?.request?.amount
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches4 =
              item?.request?.businessClassByBusinessClass?.name
                ?.toString()
                .toLowerCase()
                ?.includes(searchTerm?.toLowerCase());
            const nameMatches5 = item?.request?.company_staff?.name
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches6 = item?.request?.cover_type
              ?.toString()
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches7 = item?.request?.payment_channel
              ?.toString()
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches8 = item?.request?.sub_agent?.first_name
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches9 = item?.request?.sub_agent?.last_name
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
            const nameMatches10 = item?.request?.sub_agent?.email
              ?.toString()
              .toLowerCase()
              ?.includes(searchTerm?.toLowerCase());

            return (
              policy_number ||
              nameMatches1 ||
              nameMatches2 ||
              nameMatches3 ||
              nameMatches4 ||
              nameMatches5 ||
              nameMatches6 ||
              nameMatches10 ||
              nameMatches7 ||
              nameMatches8 ||
              nameMatches9
            );
          } else {
            return false; // Handle other filter types if needed
          }
        })
      : [];

    setFilteredData(filteredItems);
  }, [
    searchTerm,
    filter,
    subscriptionsData?.system_client_payment,
    tax_payable?.tax_payable,
    policyData?.policy,
    customer?.customers,
    commissionData?.view_payment_requistion_details,
  ]);

  const handleDownload = () => {
    // Check if there is data to download
    if (filteredData.length === 0) {
      alert("No data to download.");
      return;
    }

    // Define custom headers based on the selected filter
    const firstItem = filteredData[0];
    const headers = Object.keys(firstItem);

    // Transform data to match headers
    const transformedData = filteredData.map((item: any) => {
      const transformedItem: Record<string, any> = {};
      headers.forEach((header) => {
        if (typeof item[header] === "object") {
          transformedItem[header] = JSON.stringify(item[header]);
        } else {
          transformedItem[header] = item[header];
        }
      });
      return transformedItem;
    });

    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData,
    });

    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filter}.csv`;
    document.body.appendChild(a);

    // Trigger a click on the link to start the download
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Reports" />

      <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-col lg:flex-row gap-3  w-full">
          <div className="flex flex-row items-center w-full mr-3">
            <Typography className="w-28">Filter by</Typography>
            <select
              className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-1/4 lg:w-1/4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => handleFilterChange(e?.target?.value)}
            >
              <option selected disabled>
                Select filter
              </option>
              {options?.map((item) => (
                <option
                  className="capitalize"
                  value={item}
                  key={item}
                  label={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          {/* <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                From
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full lg:max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                To
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div> */}

          {/* <Button
            // onClick={() => refetch()}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-xs font-normal w-1/2"
            size="md"
          >
            Generate Report
          </Button> */}
        </div>
      </div>

      <div className="mt-5 bg-white p-3 rounded-lg">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-3 flex items-center justify-between gap-8">
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <div className="w-full md:w-72">
                <Input
                  crossOrigin="anonymous"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  label="Search"
                  icon={<BiSearch className="h-5 w-5" />}
                />
              </div>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0">
          <table className="w-full  table-auto text-left">
            {filter === "subscriptions" && Subscriptions_Table_Head && (
              <HeaderRenderer tableHead={Subscriptions_Table_Head} />
            )}

            {filter === "taxes payment" && Tax_HEADERS && (
              <HeaderRenderer tableHead={Tax_HEADERS} />
            )}

            {filter === "Commissions" && Commissions_HEADERS && (
              <HeaderRenderer tableHead={Commissions_HEADERS} />
            )}
            {filter === "policy" && Policy_HEADERS && (
              <HeaderRenderer tableHead={Policy_HEADERS} />
            )}
            {filter === "Customers" && Customers_HEADERS && (
              <HeaderRenderer tableHead={Customers_HEADERS} />
            )}

            <tbody>
              {filteredData?.map((item: any, index: number) => {
                const isLast = index === filteredData?.length - 1;
                const classes = isLast
                  ? "px-2 py-4  text-center"
                  : "px-2 py-4 border-b-2 border-blue-gray-50 text-center";

                return (
                  <>
                    {filter === "subscriptions" ? (
                      <SubscriptionTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : filter === "taxes payment" ? (
                      <TaxTable classes={classes} item={item} index={index} />
                    ) : filter === "policy" ? (
                      <PolicyTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : filter === "Customers" ? (
                      <CustomerTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : filter === "Commissions" ? (
                      <ComissionTable
                        classes={classes}
                        item={item}
                        index={index}
                      />
                    ) : null}
                  </>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        {filter === "subscriptions" &&
        subscriptionsData?.system_client_payment?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        ) : filter === "taxes payment" &&
          tax_payable?.tax_payable?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        ) : filter === "policy" && policyData?.policy?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        ) : filter === "Customers" && customer?.customers?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        ) : filter === "Commissions" &&
          commissionData?.view_payment_requistion_details?.length! < 1 ? (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="black" variant="h6">
              No data found
            </Typography>
          </div>
        ) : null}
      </div>
    </>
  );
};

const HeaderRenderer = ({ tableHead }: { tableHead: string[] }) => (
  <thead>
    <tr>
      {tableHead.map((head) => (
        <th
          key={head}
          className="capitalize text-center border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
        >
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal leading-none opacity-70"
          >
            {head}
          </Typography>
        </th>
      ))}
    </tr>
  </thead>
);

const PolicyTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: PolicyType;
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.policy_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {`${item?.certificate_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.request?.amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.request?.payment_channel}`}
        </Typography>
      </td>
      <td className={classes}>
        <div className="flex flex-col gap-2 text-left">
          <Typography
            variant="small"
            color="blue-gray"
            className="text-xs capitalize "
          >
            Name:{" "}
            <span className="font-normal">
              {`${item?.request?.sub_agent?.first_name} ${item?.request?.sub_agent?.last_name}`}
            </span>
          </Typography>
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-xs capitalize "
          >
            Email:{" "}
            <span className="font-normal">
              {item?.request?.sub_agent?.email}
            </span>
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="text-xs capitalize "
        >
          {item?.request?.company_staff?.name}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="text-xs capitalize "
        >
          {item?.request?.businessClassByBusinessClass?.name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="text-xs capitalize "
        >
          {item?.request?.cover_type}
        </Typography>
      </td>
    </tr>
  </>
);

const ComissionTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: CommissionsType;
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.payee_name}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {`${item?.payee_type}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.payee_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.payee_details}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.payment_stage}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {`${item?.rate}`}%
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.reference_amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.transaction_name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.transaction_category}
        </Typography>
      </td>
      <td className={classes}>
        <div className="flex flex-col gap-2 text-left">
          <Typography
            variant="small"
            color="blue-gray"
            className="text-xs capitalize "
          >
            Bank:{" "}
            <span className="font-normal">
              {item?.account_details?.bank_name}
            </span>
          </Typography>
          <Typography
            variant="small"
            color="blue-gray"
            className=" text-xs capitalize "
          >
            Branch:{" "}
            <span className="font-normal">{item?.account_details?.branch}</span>
          </Typography>
        </div>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.status}
        </Typography>
      </td>
    </tr>
  </>
);

const TaxTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: TaxType;
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.tax?.name}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          GH₵{" "}
          {(parseFloat(item?.amount as any) ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.commision?.name}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.commissions_payable?.commission_amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.rate}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.commissions_payable?.reference_amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.commissions_payable?.status}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.created_at).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
);

const SubscriptionTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: SubscriptionType;
}) => (
  <>
    {" "}
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.reference_number}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {item?.policy?.policy_number}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.policy?.request?.cover_type}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.amount as any) ?? 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(
            parseFloat(item?.policy?.request?.amount as any) ?? 0
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          GH₵{" "}
          {(parseFloat(item?.subscription_amount as any) ?? 0).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.policy?.request?.businessClassByBusinessClass?.name}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.datecreated).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
);

const CustomerTable = ({
  index,
  classes,
  item,
}: {
  index: number;
  classes: string;
  item: CustomerType;
}) => (
  <>
    <tr
      key={index.toString()}
      className="hover:bg-gray-100 transition-all hover:cursor-pointer"
    >
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className=" font-normal text-xs capitalize"
        >
          {`${item?.first_name} ${item?.last_name}`}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {item?.email}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.phone_number}
        </Typography>
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs capitalize"
        >
          {item?.customer_type}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          color="blue-gray"
          className="font-normal text-xs"
        >
          {moment(item?.created_at).format("LLL")}
        </Typography>
      </td>
    </tr>
  </>
);

export default ReportView;
