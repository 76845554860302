import {
  Button,
  CardBody,
  DialogFooter,
  IconButton,
  Input,
  Spinner,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState, useMemo } from "react";
import {
  CustomDeleteModal,
  CustomModal,
  DetailDrawer,
  HeaderIcon,
  RenderAdminDetail,
} from "../../../components";
import useSWR from "swr";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import { AdminType, UpdateType } from "../../../types/admin";
import { ToastContainer, toast } from "react-toastify";
import { UserType } from "../../../types/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { AddAdminModal } from "../../../components/forms/add-admin";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import { useHistory } from "react-router-dom";
import { BiBlock } from "react-icons/bi";
import { DASHBOARD_USERTYPE } from "../../../types/role";
import moment from "moment";

type ResponseType = {
  "Transaction Ref": string;
  "Transaction Id":string;
  "Transaction Date": string;
  "Policy Number": string;
  Policyholder: string;
  "Class/Subclass": string;
  "Basic Premium": number;
  "Commission Rate": string;
  "Gross Commission": number;
  "Tax Amount": number;
  "Net Commission": number;
};

const PaidCommissions = () => {
  const [searchText, setSearchText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, SetShowDeleteModal] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<ResponseType>();
  const [commissions, setCommissions] = useState<ResponseType[]>([]);

  const PAGE_SIZE = 10;

  const handleRowClick = (item: ResponseType) => {
    setSelectedRow(item);
    setOpenDrawer(true);
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (admin: ResponseType) => {
    const isTextMatch =
      admin?.["Transaction Ref"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policy Number"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policyholder"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Class/Subclass"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Commission Rate"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  const fetchPaidComissions = async () => {
    try {
      const res = await fetch(`${BASEURL}/get-paid-commissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          usertype: DASHBOARD_USERTYPE.company,
          company_id: localStorage.getItem("company_id"),
        }),
      });
      const req = await res.json();
      if (res.ok) {
        if (req?.error == 404) {
          setCommissions([])
        }
        else {
          setCommissions(req?.data);
        }

      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error("Failed to fetch commissions. Refresh to try again");
    }
  };


 


  const paginatedData = commissions?.filter(filterData)?.slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);


  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (commissions?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  useEffect(() => {
    fetchPaidComissions();
  }, []);

  const handlePrint = (rowData: ResponseType) => {
    // Create a new window for printing
    const printWindow = window.open("", "_blank");

    // Construct the content to be printed
    const printContent = `
      <html>
        <head>
          <title>${rowData["Transaction Ref"]}</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              text-align: center;
              font-size: 24px;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 10px;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          <h1>Paid Commission #${rowData["Transaction Ref"]}</h1>
          <table>
            <thead>
              <tr>
                <th>Transaction Ref</th>
                <th>Transaction Date</th>
                <th>Policy Number</th>
                <th>Payee</th>
                <th>Class/Subclass</th>
                <th>Basic Premium</th>
                <th>Commission Rate</th>
                <th>Gross Commission</th>
                <th>Tax Amount</th>
                <th>Net Commission</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${rowData["Transaction Ref"]}</td>
                <td>${moment(rowData["Transaction Date"]).format("LLL")}</td>
                <td>${rowData["Policy Number"]}</td>
                <td>${rowData["Policyholder"]}</td>
                <td>${rowData["Class/Subclass"]}</td>
                <td>GHS ${(
                  (rowData?.["Basic Premium"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${rowData["Commission Rate"]}%</td>
                <td>GHS ${(
                  (rowData?.["Gross Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Tax Amount"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Net Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Write the content to the new window
    printWindow!.document.write(printContent);

    // Close the document after printing
    printWindow!.document.close();

    // Print the window
    printWindow!.print();
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      {/* <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      /> */}

      {/* <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      /> */}

      <CustomHeader title="Paid commssions" />
      {/* <div className="w-full mb-10">
        <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
          <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
            <div className="w-full mr-3">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          <div className="mx-3" />

          <Button
            onClick={handleOpen}
              className="w-full md:w-36 lg:w-36"

 style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
          >
            Add premium
          </Button>
        </div>
      </div> */}

      <div className="mt-10">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Total paid commissions
              {commissions && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {commissions?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-3 bg-white p-3 w-full">
            <div className="w-full">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div>
          </div>

          {/* //min-w-max  */}
          <table className="w-full bg-white table-auto">
            <thead className="">
              <tr>
                {[
                  "Transaction Ref",
                  "Provider transaction id",
                  "Transaction Date",
                  "Policy Number",
                  "Payee",
                  "Class/Subclass",
                  "Basic Premium",
                  "Commission Rate",
                  "Gross Commission",
                  "Tax Amount",
                  "Net Commission",
                  "",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index: number) => {
                const isLast = index === paginatedData?.length - 1;
                const classes = isLast
                  ? "p-2  text-center"
                  : "p-2 border-b-2 border-blue-gray-50 text-center";

                return (
                  <tr
                    key={item?.["Policy Number"] + index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item["Transaction Ref"]}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item["Transaction Id"]}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {moment(item?.["Transaction Date"]).format("LLL")}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize "
                      >
                        {item?.["Policy Number"]}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {`${item?.Policyholder}`}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.["Class/Subclass"]}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS {item?.["Basic Premium"]}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.["Commission Rate"]}%
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {(
                          (item?.["Gross Commission"] as any) ?? 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {((item?.["Tax Amount"] as any) ?? 0).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {(
                          (item?.["Net Commission"] as any) ?? 0
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Button
                        onClick={() => handlePrint(item)}
                        size="sm"
                        color="green"
                        className="w-full flex items-center justify-center text-[11px] font-normal capitalize"
                      >
                        Print
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        {loading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )}

        {!paginatedData && !loading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {/* Paginator */}
        {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="filled"
                onClick={prevPage}
                disabled={activePage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page <strong className="text-white">{activePage}</strong> of{" "}
                <strong className="text-white">
                  {Math.ceil(
                    (commissions?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="filled"
                onClick={nextPage}
                disabled={
                  activePage ===
                  Math.ceil(
                    (commissions?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaidCommissions;
