import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { BiEdit, BiPlus, BiTrash } from "react-icons/bi";
import { FiDelete } from "react-icons/fi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_TOWNS_OF_DISTRICT } from "../../../../GraphQl/queries";
import { CREATE_TOWN } from "../../../../GraphQl/mutations";
import ConfirmationDialog from "../../../../config/confirmationbox";
import { toast } from "react-toastify";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../../util";
import { UserType } from "../../../../types/user";

interface Params {
  id: string; // Define the type for the 'id' parameter
}

type DataType = {
  id: string;
  name: string;
  district: {
    region: {
      name: string;
    };
    name: string;
  };
};

const ManageTown = () => {
  const [searchText, setSearchText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const PAGE_SIZE = 8;
  const { id } = useParams<Params>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    data: towns,
    loading,
    refetch,
  } = useQuery<{ towns: DataType[] }>(GET_ALL_TOWNS_OF_DISTRICT, {
    variables: {
      districtID: id,
    },
  });

  const [selectedFile, setSelectedFile] = useState<File | null | any>(null);

  const [formData, setFormData] = useState({
    name: "",
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(e);
  };

  const [AddTown, { error, loading: RequestLoading }] = useMutation(
    CREATE_TOWN,
    {
      onCompleted: (data) => {
        toast.success("You have successfully added a town");
        setSubmitting(false);

        refetch();
      },
      onError: (error) => toast.success(error?.message ?? "Failed to add town"),
    }
  );

  const addTownwithfile = async () => {
    const _formData = new FormData();

    // Convert the selected file to base64
    _formData.append("file", selectedFile);

    try {
      setSubmitting(true);
      const response = await fetch(
        `${BASEURL}/setup/upload-town-bulk?district_id=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: _formData,
        }
      );

      const req = await response.json();

      if (response.ok) {
        toast.success(req?.message ?? "File Successfully added");
      } else {
        toast.info(req.detail ?? req.message);
      }
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error?.message ?? "Failed to add towns");
    }
  };

  // Helper function to read file as data URL
  const readFileAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result); // Return the entire data URL
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleConfirmSubmit = () => {
    if (!formData?.name && !selectedFile) {
      return toast.error("Name or file not added");
    }
    if (selectedFile) {
      addTownwithfile();
    } else {
      setSubmitting(true);

      AddTown({
        variables: {
          name: formData.name,
          district_id: id,
        },
      });
    }
    setShowConfirmation(false);
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setActivePage(1);
  };

  const filterData = (item: DataType) => {
    const isTextMatch =
      item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.district?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.district?.region?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    return isTextMatch;
  };

  // Filtered and paginated data for the current page
  const paginatedData = towns?.towns
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (towns?.towns?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="towns" />
      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={handleConfirmSubmit}
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          {/* <div className="bg-black p-3">
            <Typography className="text-base text-white">Region</Typography>
          </div> */}
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Region name", "district name"].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {towns && towns.towns.length > 0 && (
                <tr className="transition-all bg-gray-300">
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {towns?.towns[0]?.district?.region.name}
                    </Typography>
                  </td>
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-xs capitalize "
                    >
                      {towns?.towns[0]?.district?.name}
                    </Typography>
                  </td>
                  {/* Add other columns or data you want from the first item */}
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-base text-white">
              Create Town
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Town
                    </Typography>
                    <Input
                      crossOrigin="anonymous"
                      size="lg"
                      value={formData?.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <hr />
                  <div className="w-full my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Or Add town with file
                    </Typography>

                    <input
                      type="file"
                      multiple={false}
                      onChange={handleFileChange}
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900 bg-gray-100 p-2 h-12 w-full rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end">
              <Button
                onClick={() => setShowConfirmation(true)}
                size="sm"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
                className="flex flex-row items-center font-normal"
              >
                <BiPlus size={20} />
                {submitting ? "Saving..." : "Save town"}
              </Button>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-base capitalize">towns</Typography>
          </div>
          <div className="py-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto">
                <thead className="">
                  <tr>
                    {["name", ""].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-normal text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1;
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center";

                    return (
                      <tr
                        key={item?.name + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.name}
                          </Typography>
                        </td>
                        {/* <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              Head office
                            </Typography>
                          </td> */}

                        <td className={classes}>
                          {/* <button className="font-normal text-xs capitalize bg-red-600 p-2 rounded-md text-white hover:shadow-md">
                            Manage district
                          </button> */}
                          <IconButton color="red" size="sm">
                            <BiTrash />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex text-white items-center justify-center">
                <Typography variant="h6">No data found</Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && (
              <div className="my-10 flex items-center justify-center">
                <div className="flex items-center gap-8">
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={prevPage}
                    disabled={activePage === 1}
                  >
                    <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                  <Typography color="gray" className="font-normal">
                    Page <strong className="text-gray-700">{activePage}</strong>{" "}
                    of{" "}
                    <strong className="text-gray-700">
                      {Math.ceil(
                        (towns?.towns?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )}
                    </strong>
                  </Typography>
                  <IconButton
                    size="sm"
                    variant="filled"
                    onClick={nextPage}
                    disabled={
                      activePage ===
                      Math.ceil(
                        (towns?.towns?.filter(filterData).length || 0) /
                          PAGE_SIZE
                      )
                    }
                  >
                    <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  );
};

export default ManageTown;
