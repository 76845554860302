import React, { useEffect, useState } from "react";
import { CustomHeader } from "../../../components/headers/CustomHeader";
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useQuery } from "@apollo/client";
import {
  GETALLAGENTS,
  GETALLFUNDSREQUEST,
  GETALLFUNDSREQUESTPENDING,
  GETBALANCEOFUSERS,
  GETCOMPANYSTAFF,
} from "../../../GraphQl/queries";
import { AgentType, FundsType } from "../accountfunding";
import { ToastContainer, toast } from "react-toastify";
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util";
import { DASHBOARD_USERTYPE, parameter_type } from "../../../types/role";
import moment from "moment";
import { BiPlus, BiTrash } from "react-icons/bi";
import { CustomModal } from "../../../components";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserType } from "../../../types/user";

type BalanceType = {
  name: string;
  balance: string;

  record_id: string;
  record_type: string;
};

type FormType = {
  amount: number;
};

const FundingApproval = () => {
  const [submitting, setSubmitting] = useState(false);
  const [searchRequestText, setRequestText] = useState("");
  const [searchBalanceText, setBalanceText] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [showCashModal, setCashModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({
    parameter_id: "",
    parameter_type: "",
  });

  const [activePage2, setActivePage2] = useState(1);
  const PAGE_SIZE = 10;

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormType>();

  const {
    data: balances,
    loading: loadingBalances,
    refetch: refetchBalance,
  } = useQuery<{
    positive_balance_view: BalanceType[];
  }>(GETBALANCEOFUSERS, {
    variables: {
      company_id: localStorage.getItem("company_id")
    }
  }
  );

  const {
    data: funds,
    loading: loadingFunds,
    refetch: refchFunds,
  } = useQuery<{
    view_fund_account_details: FundsType[];
  }>(GETALLFUNDSREQUESTPENDING, {
    variables: {
      company_id: localStorage.getItem("company_id")
    }
  });

  const { data: staffs, loading: loadingStaff } = useQuery<{
    company_staff: {
      id: string;
      name: string;
    }[];
  }>(GETCOMPANYSTAFF, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const { data: agents, loading: loadingAgents } = useQuery<{
    agents: AgentType[];
  }>(GETALLAGENTS, {
    variables: {
      company_id: localStorage.getItem("company_id"),
    },
  });

  const handleSearch = (e: any) => {
    setRequestText(e.target.value);
    setActivePage(1);
  };

  const handleBalanceSearch = (e: any) => {
    setBalanceText(e.target.value);
    setActivePage2(1);
  };

  const filterData = (filter: FundsType) => {
    const isTextMatch =
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.branch_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.division_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.role_balance
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_staff_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.status
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase());

    return isTextMatch;
  };

  const filterBalance = (filter: BalanceType) => {
    const isTextMatch = filter?.name
      ?.toLowerCase()
      ?.includes(searchBalanceText?.toLowerCase());
    return isTextMatch;
  };

  const paginatedBalanceData = balances?.positive_balance_view
    ?.filter(filterBalance)
    .slice((activePage2 - 1) * PAGE_SIZE, activePage2 * PAGE_SIZE);

  const paginatedFundsData = funds?.view_fund_account_details
    ?.filter(filterData)
    .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE);

  const nextPageFunding = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (funds?.view_fund_account_details?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1);
    }
  };

  const nextPage = () => {
    if (
      (activePage2 - 1) * PAGE_SIZE <
      (balances?.positive_balance_view?.filter(filterBalance).length || 0)
    ) {
      setActivePage2(activePage2 + 1);
    }
  };

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
    if (activePage2 > 1) {
      setActivePage2(activePage - 1);
    }
  };

  const handleApprove = async (item: FundsType, response: string) => {
    const payload = {
      request_id: item?.funds_request_id,
      parameter_type: item?.parameter_type,
      parameter_id: item?.parameter_id,
      previous_balance:
        item?.parameter_type === parameter_type.agent_id
          ? item?.agent_balance ?? "0"
          : item?.parameter_type === parameter_type.staff_id
            ? item?.staff_balance ?? "0"
            : item?.parameter_type === parameter_type?.branch_id
              ? item?.branch_balance ?? "0"
              : item?.parameter_type === parameter_type?.division_id
                ? item?.division_balance ?? "0"
                : item?.role_balance ?? "0",
      amount: item?.amount,
      response: response,
      usertype: DASHBOARD_USERTYPE.company,
    };
    setSubmitting(true);
    try {
      const res = await fetch(`${BASEURL}/approve-users-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      const req = await res.json();

      if (res.ok) {
        toast.success(req?.message);
        refchFunds();
        refetchBalance();
      } else {
        toast.error(req?.detail ?? req?.message);
      }
      setSubmitting(false);
    } catch (error: any) {
      setSubmitting(false);

      toast.error(error?.message ?? "An error occured");
    }
  };

  const removeBalance = async (record_id: string, record_type: string) => {
    try {
      const res = await fetch(`${BASEURL}/remove-users-balance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          record_id,
          record_type,
          usertype: DASHBOARD_USERTYPE.company,
        }),
      });

      const req = await res.json();

      if (res.ok) {
        toast.success(req?.message);
        refchFunds();
        refetchBalance();
      } else {
        toast.error(req?.detail ?? req?.message);
      }
    } catch (error: any) {
      toast.error(error?.message ?? "An error occured");
    }
  };

  const onUpdateClick = (parameter_type: string, parameter_id: string) => {
    setSelectedRow({
      parameter_id,
      parameter_type,
    });
    setCashModal(true);
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    try {
      let payload = {
        user_id: selectedRow?.parameter_id,
        parameter_type: selectedRow?.parameter_type,
        amount: data?.amount,
        usertype: DASHBOARD_USERTYPE.company,
      };

      const res = await fetch(`${BASEURL}/update-cash-limit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });

      const req = await res.json();

      if (res.ok) {
        toast.success(req?.message ?? "Cash limit updated successfully");
        reset();
        refchFunds();
        refetchBalance();
        setCashModal(false);
      } else {
        toast.error(req?.detail ?? req?.message);
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to update cash limit");
    }
  };

  const userData = localStorage.getItem("user");
  const [colors, setColors] = useState<string[]>();

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData);

      setColors(data.colors);
    }
  }, []);

  return (
    <>
      <CustomHeader title="Funding Request" />

      <CustomModal
        title="Update cash limit"
        onClose={() => setCashModal(false)}
        open={showCashModal}
        children={
          <>
            <ToastContainer />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" rounded-lg overflow-hidden w-full"
            >
              <div className="">
                <div className="flex flex-col items-end justify-end">
                  <div className="w-full">
                    <div className="mb-6 flex flex-col gap-2">
                      <div className="w-full flex flex-col lg:items-center md:flex-col lg:flex-row my-1 gap-1">
                        <Typography
                          variant="h6"
                          color="blue-gray"
                          className="mb-2 font-semibold text-[14px] w-1/3"
                        >
                          Cash limit amount
                        </Typography>
                        <Input
                          className="w-full h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 capitalize dark:focus:border-blue-500"
                          crossOrigin="anonymous"
                          label="Amount"
                          {...register("amount")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-end mt-5">
                <Button
                  type="submit"
                  size="sm"
                  style={{
                    background:
                      colors?.length! > 1
                        ? `${colors![0]?.toLowerCase()}`
                        : `${DEFAULTPRIMARYCOLOR}`,
                  }}
                  className="flex flex-row items-center font-normal"
                >
                  <BiPlus size={20} />

                  {isSubmitting ? (
                    <Spinner className="w-4 h-4" />
                  ) : (
                    "Update cash limit"
                  )}
                </Button>
              </div>
            </form>
          </>
        }
      />

      <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
        <div className="bg-black p-3 text-white">
          <Typography className="text-lg">
            List of All Account Funding Requests
          </Typography>
        </div>
        <div className="p-3 bg-white rounded-b-xl">
          <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
            <div className="p-3">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchRequestText}
                  onChange={handleSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-center">
              <thead className="">
                <tr>
                  {[
                    "no",
                    "Account",
                    "Amount",
                    "Account Limit",
                    "Date Requested",
                    "",
                  ].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedFundsData?.map((item, index: number) => {
                  const isLast = index === paginatedFundsData?.length - 1;
                  const classes = isLast
                    ? "px-4 py-2  text-center"
                    : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                  return (
                    <tr
                      key={index.toString()}
                      className="hover:bg-gray-100 transition-all even:bg-gray-200"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {index}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.parameter_type === parameter_type.agent_id
                            ? item?.agent
                            : item?.parameter_type === parameter_type.staff_id
                              ? item?.company_staff_name
                              : item?.parameter_type === parameter_type?.branch_id
                                ? item?.branch_name
                                : item?.parameter_type ===
                                  parameter_type?.division_id
                                  ? item?.division_name
                                  : item?.company_role_name}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {item?.parameter_type === parameter_type.agent_id
                            ? (
                              parseInt(item?.agent_cash_limit) || 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : (
                              parseInt(item?.staff_cash_limit) || 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {moment(item?.created_at).format("LLLL")}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <div className="w-full flex flex-row items-center gap-x-2">
                          <Button
                            onClick={() => handleApprove(item, "approve")}
                            size="sm"
                            color="green"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            approve
                          </Button>
                          <Button
                            onClick={() => handleApprove(item, "disapprove")}
                            size="sm"
                            color="red"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            disapprove
                          </Button>
                          <Button
                            onClick={() =>
                              onUpdateClick(
                                item?.parameter_type,
                                item?.parameter_id
                              )
                            }
                            size="sm"
                            color="green"
                            className="w-full flex items-center justify-center text-[11px] font-normal capitalize"
                          >
                            Update cash limit
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>

          {loadingFunds && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}

          {!paginatedFundsData && !loadingFunds && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {/* Paginator */}
          {paginatedFundsData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prevPage}
                  disabled={activePage === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                  Page <strong className="text-gray-900">{activePage}</strong>{" "}
                  of{" "}
                  <strong className="text-gray-900">
                    {Math.ceil(
                      (funds?.view_fund_account_details?.filter(filterData)
                        .length || 0) / PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={nextPageFunding}
                  disabled={
                    activePage ===
                    Math.ceil(
                      (funds?.view_fund_account_details?.filter(filterData)
                        .length || 0) / PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full rounded-lg lg:col-span-2 overflow-hidden mt-10">
        <div className="bg-black p-3 text-white">
          <Typography className="text-lg">Account balances</Typography>
        </div>
        <div className="p-3 bg-white rounded-b-xl">
          <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
            <div className="p-3">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchBalanceText}
                  onChange={handleBalanceSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-center">
              <thead className="">
                <tr>
                  {["no", "Account", "Balance", ""].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBalanceData?.map((item, index: number) => {
                  const isLast = index === paginatedBalanceData?.length - 1;
                  const classes = isLast
                    ? "px-4 py-2  text-center"
                    : "px-4 py-2 border-b-2 border-blue-gray-50 text-center";

                  return (
                    <tr
                      key={index.toString()}
                      className="hover:bg-gray-100 transition-all even:bg-gray-200"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {index}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.name}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.balance).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Delete">
                          <IconButton
                            onClick={() =>
                              removeBalance(item?.record_id, item?.record_type)
                            }
                            size="sm"
                            color="red"
                            className="w-full flex items-center justify-center text-xs font-normal capitalize"
                          >
                            <BiTrash />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>

          {loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}

          {!paginatedBalanceData && !loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {/* Paginator */}
          {paginatedBalanceData && (
            <div className="my-10 flex items-center justify-center">
              <div className="flex items-center gap-8">
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={prevPage}
                  disabled={activePage2 === 1}
                >
                  <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
                </IconButton>
                <Typography color="gray" className="font-normal">
                  Page <strong className="text-gray-900">{activePage2}</strong>{" "}
                  of{" "}
                  <strong className="text-gray-900">
                    {Math.ceil(
                      (balances?.positive_balance_view?.filter(filterBalance)
                        .length || 0) / PAGE_SIZE
                    )}
                  </strong>
                </Typography>
                <IconButton
                  size="sm"
                  variant="outlined"
                  onClick={nextPage}
                  disabled={
                    activePage2 ===
                    Math.ceil(
                      (balances?.positive_balance_view?.filter(filterBalance)
                        .length || 0) / PAGE_SIZE
                    )
                  }
                >
                  <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="h-20" />
    </>
  );
};

export default FundingApproval;
